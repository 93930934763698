import Tortoise from '@/components/tortoise.js'
import Egg from '@/components/egg.js'
import Status from '@/components/status'
import BathTortoise from '@/components/bath_tortoise.js'
import GM from '@/components/game_manager'
import collection_manager from '@/components/collection_manager'
import audio_manager from "@/components/audio_manager"
import place_manager from '@/components/place_manager'
import Poop from '@/components/poop'
import {VARIETIES} from '@/libs/varieties.js'
import { GAME_OBJECT_DEPTH } from '@/settings/depth'

export default class TortoiseSoul {
  constructor(record = {}) {
    this.record = record
    this.variety_id = record.variety_id != null ? record.variety_id : 1
    this.place_idx = record.place_idx != null ? record.place_idx : 0
    this.name = record.name != null ? record.name : '' // 暱稱
    this.status = new Status(this, record)

    // 實體是什麼型態：'egg' | 'tortoise' | 'bath_tortoise'
    this.state = null

    // 在畫面上顯示的 phaser 物件
    this.body = null

    this.highlight_effect = null
    this.heal_popup = null
    this.name_effect = null
  }

  get highlight_position() {
    if (!this.body || !this.body.sprite) return null

    // offset: 距離soul中心的偏移
    let offset_x = 0
    let offset_y = 30
    let effect_position = {
      x: this.body.sprite.x + offset_x,
      y: this.body.sprite.y + offset_y
    }
    return effect_position
  }

  get name_effect_position() {
    if (!this.body || !this.body.sprite) return null

    // offset: 距離soul中心的偏移
    let offset_y = - this.body.sprite.displayHeight / 2 - 10
    let effect_position = {
      x: this.body.sprite.x,
      y: this.body.sprite.y + offset_y
    }
    return effect_position
  }

  get heal_popup_position() {
    if (!this.body || !this.body.sprite) return null

    // offset: 距離soul中心的偏移
    let offset_x = 0
    let offset_y = -this.body.sprite.displayHeight / 2  // 定位在切齊 sprite 頂端
    let effect_position = {
      x: this.body.sprite.x + offset_x,
      y: this.body.sprite.y + offset_y
    }
    return effect_position
  }

  get profile_pic_path() {
    if (this.status && this.status.has_birth) {
      return VARIETIES[this.variety_id].img
    }
    return require("@/assets/imgs/egg.png")
  }

  // 在 scene.create 時被呼叫
  create(scene, initial_pos) {
    if (this.body) return

    if (GM.current_scene_key === 'PlayScene') {
      // 判斷烏龜是否已出生：若 record 有 time_of_birth，代表烏龜已出生
      if (this.status && this.status.has_birth) {
        this.body = this.create_tortoise(scene, initial_pos)
      }
      else {
        this.body = this.create_egg(scene, initial_pos)
      }
    }
    else if (GM.current_scene_key === 'BathScene') {
      this.body = this.create_bath_tortoise(scene, initial_pos)
    }

    this.check_highlight()
    this.check_sick()
    this.check_name()
  }

  update() {
    if (this.body) this.body.update()

    this.check_highlight()
    this.check_sick()
    this.check_name()
  }

  destroy() {
    if (this.body) this.body.destroy()
    this.body = null

    this.clear_heal_popup()
    this.unhighlight()
    if (this.name_effect) {
      this.name_effect.destroy()
      this.name_effect = null
    }
  }

  create_egg(scene, initial_pos) {
    this.state = 'egg'
    return new Egg(scene, this, initial_pos)
  }

  create_tortoise(scene, initial_pos) {
    this.state = 'tortoise'
    return new Tortoise(scene, this, initial_pos)
  }

  create_bath_tortoise(scene, initial_pos) {
    if (!this.status.has_birth) return null
    this.state = 'bath_tortoise'
    return new BathTortoise(scene, this, initial_pos)
  }

  check_highlight() {
    if (GM.selected_soul === this) {
      if (!this.highlight_effect) {
        this.highlight()
      }
      else {
        // TODO: 目前是用每次 update 都重設 highlight 位置的方法，也許可以用 container 包起來一起移動，但初步研究有點複雜
        if (this.highlight_position) this.highlight_effect.setPosition(this.highlight_position.x, this.highlight_position.y)
      }
    }
  }

  check_name() {
    if (!this.name_effect) {
      this.show_tortoise_name()
    }
    else {
      if (this.name_effect_position) this.name_effect.setPosition(this.name_effect_position.x, this.name_effect_position.y)
    }
  }

  highlight() {
    if (this.highlight_effect) this.highlight_effect.destroy()
    this.highlight_effect = null
    if (this.body && this.body.scene && this.highlight_position) {
      this.highlight_effect = this.body.scene.add.image(this.highlight_position.x, this.highlight_position.y, 'tortoise_highlight')
        .setScale(0.23)
        .setDepth(GAME_OBJECT_DEPTH.highlight)
      this.body.sprite.setDepth(GAME_OBJECT_DEPTH.highlight_body)
    }
  }

  show_tortoise_name() {
    if (this.name_effect) this.name_effect.destroy()
    this.name_effect = null
    if (this.body && this.body.scene && this.name_effect_position) {
      this.name_effect = this.body.scene.add.text(this.name_effect_position.x, this.name_effect_position.y, this.name)
        .setScale(1)
        .setOrigin(0.5, 0.5)
        .setDepth(GAME_OBJECT_DEPTH.name)
        .setShadow(1, 1, 'rgba(0, 0, 0, 0.5)', 1)
    }
  }

  unhighlight() {
    if (this.highlight_effect) this.highlight_effect.destroy()
    this.highlight_effect = null
    if (this.body) {
      if (this.state === 'egg') {
        this.body.sprite.setDepth(GAME_OBJECT_DEPTH.egg)
      }
      else {
        this.body.sprite.setDepth(GAME_OBJECT_DEPTH.tortoise)
      }
    }
  }

  check_sick() {
    if (this.status.is_sick) {
      if (!this.heal_popup) {
        this.show_heal_popup()
      }
      else {
        if (this.heal_popup_position) this.heal_popup.setPosition(this.heal_popup_position.x, this.heal_popup_position.y)
      }
    }
    else {
      if (this.heal_popup) {
        this.clear_heal_popup()
        this.body.sprite.clearTint()
        this.body.state = null  // 治療完讓烏龜恢復預設動作
      }
    }
  }

  show_heal_popup() {
    if (this.body && this.body.scene && this.heal_popup_position) {
      this.heal_popup = this.body.scene.add.image(this.heal_popup_position.x, this.heal_popup_position.y, 'heal_popup')
        .setScale(0.15)
        .setOrigin(0.5, 1)  // 定位點設在 popup 中間下方（角的位置）
        .setInteractive()
        .on('pointerup', () => {
          audio_manager.play_audio('sound_effect', 'click_btn')
          GM.$emit("click_heal_popup", this)
        })
      this.heal_popup.setDepth(GAME_OBJECT_DEPTH.heal_popup)
    }
    // 綠色濾鏡
    if (this.body) {
      this.body.sprite.setTint(0x86EF7D)
    }
  }

  clear_heal_popup() {
    if (this.heal_popup) this.heal_popup.destroy()
    this.heal_popup = null
  }

  // 出生
  give_birth_to_tortoise() {
    let egg = this.body
    let tortoise_initial_pos = {
      x: egg.sprite.x,
      y: egg.sprite.y
    }
    this.body = this.create_tortoise(egg.scene, tortoise_initial_pos)
    this.status.be_born()
    egg.destroy()

    if (GM.selected_soul === this) {
      this.body.sprite.setDepth(GAME_OBJECT_DEPTH.highlight_body)
    }

    collection_manager.add_birth(this.variety_id)
  }

  heal() {
    this.status.set_health(70)
  }

  set_name(name) {
    this.name = name
    GM.save_tortoises()

    this.clear_name_effect()
  }

  // 在更換名稱後需清除 name_effect 使其重新顯示
  clear_name_effect() {
    if (this.name_effect) this.name_effect.destroy()
    this.name_effect = null
  }

  set_place_idx(idx) {
    if (place_manager.is_place_full(idx)) return

    this.place_idx = idx
    GM.save_tortoises()

    if (idx !== GM.current_place_idx) {
      this.destroy()
      GM.set_selected_soul(null)
    }
  }

  // 由 status 觸發，只有已出生的龜才會進
  poop(is_init) {
    if (!is_init && this.place_idx === place_manager.current_place_idx) {
      new Poop(this.body.scene, this.place_idx, this.body)
    }
    if (this.state !== 'bath_tortoise') {
      place_manager.add_place_poop_amount(this.place_idx, 1)
    }
  }

  get_record() {
    // 取得要存進 localStorage 的資料
    let data = this.status.get_status_record()
    data.variety_id = this.variety_id
    data.place_idx = this.place_idx
    data.name = this.name
    return data
  }

  eliminate() {
    GM.eliminate_soul(this)
    this.destroy()

    if (GM.selected_soul === this) {
      GM.set_selected_soul(null)
    }
  }
}

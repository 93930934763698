import place_manager from '@/components/place_manager'
import GM from '@/components/game_manager'
import mission_manager from '@/components/mission_manager'
import { MISSION_CHOICES } from '@/libs/missions'
import { GAME_OBJECT_DEPTH } from '@/settings/depth'

export default class Poop {
  constructor(scene, place_idx, body) {
    this.scene = scene
    this.place_idx = place_idx
    this.body = body
    this.physics = scene.physics

    this.initial_sprite()
  }

  initial_sprite() {
    let position = null

    if (this.body) {
      position = {
        x: this.body.sprite.x + 80 * (this.body.sprite.flipX? -1 : 1),
        y: this.body.sprite.y + 20
      }
    }
    else {
      position = GM.get_random_pos_by_bounds(this.scene.ground_zone.getBounds())
    }

    this.sprite = this.scene.add.image(position.x, position.y, 'pupu')
      .setScale(0.1)
      .setDepth(GAME_OBJECT_DEPTH.poop)
      .setInteractive()
      .on('pointerup', () => {
        gtag('event', 'remove_poop')
        mission_manager.make_progress(MISSION_CHOICES.CLEAR_POOP)
        this.destroy()
        if (GM.current_scene_key !== 'BathScene') {
          place_manager.add_place_poop_amount(this.place_idx, -1)
        }
      })
  }

  destroy() {
    if (this.sprite) {
      this.sprite.removeAllListeners()
      this.sprite.destroy()
    }
  }
}

<template>
  <Modal
    :visible.sync="_visible"
    title="設定"
    :mask_required="false"
    closable
    mask_closable
  >
    <template v-slot:content>
      <div class="option_row">
        <label>
          背景音樂
        </label>
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          v-model="background_music_volume"
        >
      </div>
      <div class="option_row">
        <label>
          效果聲音
        </label>
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          v-model="sound_effect_volume"
          @change="play_test_audio"
        >
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </Modal>
</template>

<script>
import audio_manager from '@/components/audio_manager'

export default {
  name: 'Configuration',

  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      audio_manager: audio_manager
    }
  },

  computed: {
    background_music_volume: {
      get() {
        return audio_manager.configuration.background_music * 100
      },
      set(val) {
        audio_manager.set_volume('background_music', val / 100)
      }
    },

    sound_effect_volume: {
      get() {
        return audio_manager.configuration.sound_effect * 100
      },
      set(val) {
        audio_manager.set_volume('sound_effect', val / 100)
      }
    },

    _visible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },

  methods: {
    play_test_audio() {
      audio_manager.play_audio('sound_effect', 'click_btn')
    }
  }
}
</script>

<style lang="scss" scoped>
.option_row {
  display: flex;
  align-items: center;
  padding: 0 $small;

  > label {
    min-width: 60px;
    text-align: left;
  }
}

input[type="range"] {
  flex: 1;
  margin: $large $small;
  height: $small;
  background-color: rgb(110, 73, 46);
  border-radius: 50rem;

  -webkit-appearance: none;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb{
  width: 24px;
  height: 32px;
  border-radius: 12px;
  background-color: $color-gold;
  box-shadow: 0 1px 4px rgba(77, 41, 18, 0.5);
  border-style: none;

  -webkit-appearance: none;
  outline: none;
  border: none;
}
</style>

import Vue from 'vue'

/** register Modal */
import Modal from '@/libs/pt-theme/Modal'
Vue.component('Modal', Modal)

/** register Dialog */
import Dialog from '@/libs/pt-theme/Dialog.vue'
const DialogComponent = Vue.extend(Dialog)

const pt_confirm = (options) => {
  const dialog = new DialogComponent().$mount()
  let _options = options instanceof Object ? options : { content: options }
  Object.deepAssign(dialog, {
    dialog_type: 'confirm',
    title: '確認'
  }, _options)
  return dialog.launch()
}

const pt_alert = (options) => {
  const dialog = new DialogComponent().$mount()
  let _options = options instanceof Object ? options : { content: options }
  Object.deepAssign(dialog, {
    dialog_type: 'alert',
    title: '通知'
  }, _options)
  return dialog.launch()
}

Vue.prototype.$pt_confirm = pt_confirm
Vue.prototype.$pt_alert = pt_alert

export {
  pt_confirm, pt_alert
}

import Phaser from 'phaser'

import {
  WeatherVState
} from '@/state/Weather'
import GM from '@/components/game_manager'
import { VARIETIES } from '@/libs/varieties.js'
import mission_manager from '@/components/mission_manager'
import audio_manager from "@/components/audio_manager"
import { MISSION_CHOICES } from '@/libs/missions'
import { GAME_OBJECT_DEPTH } from '@/settings/depth'

const EAT_RANGE = 200
const KICK_RANGE = 100

export default class Tortoise {
  constructor(scene, soul, initial_pos) {
    this.scene = scene
    this.physics = scene.physics
    this.soul = soul
    this.status = soul.status

    this.environ_state = WeatherVState.environ_state
    this.state = null
    this.target_food = null
    this.target_point = null  // {x, y}, the point tortoise walk to

    this.init_sprite(initial_pos)
    this.init_zones()

    this.poked = false
    this.is_being_dragged = false
    this.petting_timer = null
    this.pet_cnt = 0
  }

  update() {
    if (this.status.is_dead || this.is_being_dragged) return

    if (this.status.is_sick) {
      if (this.state === null) {
        this.head_only()
      }
      return
    }

    let environ_state = WeatherVState.environ_state

    if (this.target_food) {
      if (this.state !== 'eating' && this.state !== 'kicking' && !this.poked) {
        this.goto_food()
      }
    }
    else if (!this.is_tortoise_in_right_area && this.state !== 'walking') {
      // 走到別的地方，情境1: 下雨了但龜不在陰影下，情境2: 沒下雨但龜在陰影下
      this.walk_to_random()
    }
    else if (environ_state !== this.environ_state) {
      // 天氣變了，需換成新的預設動作
      this.environ_state = environ_state
      this.init_behavior()
    }
    else if (this.state === null) {
      // 恢復預設動作，情境：走路走到終點、吃完東西
      this.init_behavior()
    }
  }

  destroy() {
    /** 有用到event和timer，要在這邊清除 */
    if (this.status) this.status.destroy()
    if (this.sprite) {
      this.sprite.removeAllListeners()
      this.sprite.destroy()
    }
    if (this.petting_timer) {
      clearTimeout(this.petting_timer)
    }
  }

  get should_in_shadow() {
    return this.environ_state && this.environ_state.includes("rainy")
  }

  get walking_zone() {
    if (this.should_in_shadow) {
      return this.shadow_zone
    }
    else {
      return this.ground_zone
    }
  }

  get is_tortoise_in_right_area() {
    if (this.should_in_shadow) {
      // 烏龜是否和陰影重疊
      return GM.is_overlaping(this.sprite, this.shadow_zone)
    }
    else {
      // 烏龜是否不和陰影重疊
      return !GM.is_overlaping(this.sprite, this.shadow_zone)
    }
  }

  init_sprite(initial_pos) {
    if (!initial_pos) {

      // 這邊因 zones 還沒生成，不能直接使用其位置
      initial_pos = GM.get_random_pos_by_bounds(this.scene.ground_zone.getBounds())
    }
    this.sprite = this.physics.add.sprite(initial_pos.x, initial_pos.y, `pt_spritesheet_${this.soul.variety_id}`)

    this.sprite.setScale(0.25)
    this.sprite.setDepth(GAME_OBJECT_DEPTH.tortoise)  // 避免被食物蓋過去

    // 先把世界邊界拿掉，這樣才不會有食物放在太邊邊讓烏龜吃不到卡住的問題
    // this.sprite.setCollideWorldBounds(true);
    this.sprite.setInteractive({ draggable: true })
    this.scene.input.setDraggable(this.sprite);
    this.sprite.on('drag', (pointer, drag_x, drag_y) => {
      this.sprite.setPosition(drag_x, drag_y)
      this.is_being_dragged = true
    })

    this.sprite.on('dragend', (pointer) => {
      this.is_being_dragged = false

      // 滑鼠移動距離大於 5，才視為拖曳
      if (pointer.getDistance() <= 5) return

      gtag('event', 'tortoise_drag')
      //小龜放下位置若不在草地上，小龜會跳回開始拖曳的位置
      if (this.sprite.getBounds().bottom < this.ground_zone.getBounds().top) {
        gtag('event', 'tortoise_drag_to_sky')
        this.sprite.setPosition(this.sprite.input.dragStartX, this.sprite.input.dragStartY)
      }
      // 被拖曳移動了位置，搜尋一下食物
      this.search_food()
    });

    this.sprite.on('pointerup', this.on_poke.bind(this))
    this.sprite.on('animationupdate', ((animation, frame, sprite) => {
      if (animation.key === `eating_${this.soul.variety_id}` && frame.textureFrame === 18) {
        this.after_eating()
      }
      else if (animation.key === `kicking_${this.soul.variety_id}` && frame.textureFrame === 29) {
        this.after_kicking()
      }
    }))
  }

  init_zones() {
    this.ground_zone = this.scene.ground_zone
    this.shadow_zone = this.scene.shadow_zone
  }

  init_behavior() {
    /*
    畫面起始時需要執行的動作
    目前需求是起始時並不會有食物 所以會在這裡不會去起去驅動吃東西
    */
    switch (this.environ_state) {
      case 'unknown':
        this.walk_to_random()
        break;

      case 'cold_clear':
        this.head_only()
        break;

      case 'cold_cloudy':
        this.hide()
        break;

      case 'cold_rainy':
        this.hide()
        break;

      case 'moderate_clear':
        this.stand()
        break;

      case 'moderate_cloudy':
        this.walk_to_random()
        break;

      case 'moderate_rainy':
        this.stand()
        break;

      default:
        this.hide()
        break;
    }
  }

  goto_food() {
    let point = {}
    if (this.target_food.position.x > this.sprite.x) {
      point = {
        x: this.target_food.position.x - this.sprite.displayWidth / 2,
        y: this.target_food.position.y - this.sprite.displayHeight * 0.3,
      }
    } else {
      point = {
        x: this.target_food.position.x + this.sprite.displayWidth / 2,
        y: this.target_food.position.y - this.sprite.displayWidth * 0.3,
      }
    }

    let distance = Phaser.Math.Distance.Between(
      this.sprite.x,
      this.sprite.y,
      point.x, point.y)

    if (distance <= 1) {
      // 飽足度=100時踢食，否則要吃
      if (this.status.is_full) {
        this.kick(this.target_food)
      }
      else {
        this.eat(this.target_food)
      }
    }
    else if ('x' in point && 'y' in point) {
      this.walk_to(point)
    }
  }

  get_food_in_sight() {
    let min_distance = null
    let closest_food = null
    const detection_range = this.status.is_full? KICK_RANGE : EAT_RANGE

    for (let food of this.scene.foods) {
      let food_point = {}
      if (food.position.x > this.sprite.x) {
        food_point = {
          x: food.position.x - this.sprite.displayWidth / 2,
          y: food.position.y - this.sprite.displayHeight * 0.3,
        }
      } else {
        food_point = {
          x: food.position.x + this.sprite.displayWidth / 2,
          y: food.position.y - this.sprite.displayWidth * 0.3,
        }
      }

      let distance = Phaser.Math.Distance.Between(
        this.sprite.x,
        this.sprite.y,
        food_point.x,
        food_point.y
      )

      // 為省效能，只要有距離小於1的食物就回傳
      if (distance <= 1) {
        return food
      }

      if (distance <= detection_range && (min_distance === null || distance < min_distance)) {
        min_distance = distance
        closest_food = food
      }
    }
    return closest_food
  }

  /**
   * 搜尋在範圍內的目標食物。
   * 這支函式不應直接被 update() 一直呼叫，呼叫時機應只有：1. 吃或踢完食物後 2. 移動或被移動後 3. 新增或移除飼料時
   */
  search_food() {
    if (this.state !== 'eating' && this.state !== 'kicking') {
      this.target_food = this.get_food_in_sight()
    }
  }

  on_poke(pointer) {
    // 滑鼠移動距離大於 5，視為拖曳，這邊就不執行點擊動作
    // 但希望保留拖曳後縮起來的行為，因此若有目標食物，此時的拖曳結束還是觸發點擊
    if (pointer.getDistance() > 5 && !this.target_food) return

    if (this.status.is_dead) return

    GM.set_selected_soul(this.soul)

    gtag('event', 'poke', {
      'category': 'interaction',
    })

    // 摸烏龜的任務
    mission_manager.make_progress([MISSION_CHOICES.PET_10, MISSION_CHOICES.PET_30, MISSION_CHOICES.PET_50])

    if (this.target_food || this.status.is_sick) {
      if (this.target_food) {
        // 紀錄玩家在餵飼料時戳烏龜
        gtag('event', 'feeding_poke')
      }

      if (this.status.is_sick) {
        this.status.play_effect('uncomfortable')
      }

      this.poked = true
      this.target_point = null
      this.hide()
      setTimeout(() => {
        this.state = null
        this.poked = false
      }, 3000)
    }
    else {
      audio_manager.play_audio('sound_effect', 'poke')

      if (this.petting_timer) {
        clearTimeout(this.petting_timer)
      }
      this.petting_timer = setTimeout(() => {
        this.pet_cnt = 0
        this.petting_timer = null
        clearTimeout(this.petting_timer)
      }, 5000)
      if (this.pet_cnt <= 15) {
        // 紀錄烏龜開心被摸
        gtag('event', 'happy_poke')
        this.status.add_happiness(1)
        this.pet_cnt += 1
      }
      else {
        // 紀錄烏龜不開心被摸
        gtag('event', 'angry_poke')
        this.walk_to_random()
        this.status.add_happiness(-1)
      }
    }
  }

  sprite_stop() {
    if (this.sprite) {
      if (this.sprite.body) this.sprite.body.stop()
      if (this.sprite.anims) this.sprite.anims.stop()
    }
  }

  die() {
    this.state = 'dead'
    this.sprite_stop()
    // TODO: 放小龜死亡 frame
  }

  be_sick() {
    this.state = 'sick'

    this.sprite_stop()
    this.sprite.anims.play(`head_only_${this.soul.variety_id}`)  // 生病目前是 head_only 的動作

  }

  hide() {
    audio_manager.play_audio('sound_effect', 'hide')

    this.state = 'hiding'

    this.sprite_stop()
    this.sprite.anims.play(`hiding_${this.soul.variety_id}`)
  }

  head_only() {
    this.state = 'head_only'

    this.sprite_stop()
    this.sprite.anims.play(`head_only_${this.soul.variety_id}`)
  }

  stand() {
    this.state = 'standing'

    this.sprite_stop()
    this.sprite.anims.play(`standing_${this.soul.variety_id}`)
  }

  eat(target_food) {
    this.state = 'eating'

    // 吃之前確認食物在烏龜的左右邊，讓頭轉到食物前
    if (target_food.position.x > this.sprite.x) {
      this.sprite.setFlipX(true)
    } else {
      this.sprite.setFlipX(false)
    }

    this.sprite_stop()
    this.sprite.anims.play(`eating_${this.soul.variety_id}`)
  }

  kick(target_food) {
    this.state = 'kicking'

    // 踢之前確認食物在烏龜的左右邊，讓頭轉到食物前
    if (target_food.position.x > this.sprite.x) {
      this.sprite.setFlipX(true)
    } else {
      this.sprite.setFlipX(false)
    }
    this.sprite_stop()
    this.sprite.anims.play(`kicking_${this.soul.variety_id}`)
  }

  walk_to_random() {
    this.walk_to(GM.get_random_pos_by_bounds(this.walking_zone.getBounds()))
  }

  async walk_to(point) {
    const is_input_same_as_target = (point) => {
      return this.target_point && this.target_point.x === point.x && this.target_point.y === point.y
    }

    // 這裡是為了避免一直指定到同個點
    if (is_input_same_as_target(point)) {
      return
    }
    else {
      this.target_point = point
    }

    this.state = 'walking'

    if (point.x > this.sprite.x) {
      this.sprite.setFlipX(true)
    } else {
      this.sprite.setFlipX(false)
    }

    this.sprite.anims.play(`walking_${this.soul.variety_id}`, true)
    // 小龜速度依小龜初速 - ((飽食度/100) * 初始速度 * 係數(0.5))
    let velocity = VARIETIES[this.soul.variety_id].velocity - (((this.soul.status.satiety / 100) * VARIETIES[this.soul.variety_id].velocity) * 0.5)
    this.physics.moveToObject(this.sprite, point, velocity)

    const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay))
    let origin_distance = null

    while (true) {
      if (this.state === 'walking' && is_input_same_as_target(point)) {
        let distance = Phaser.Math.Distance.Between(
          this.sprite.x,
          this.sprite.y,
          point.x,
          point.y)

        if (origin_distance != null && (origin_distance <= distance || distance <= 1) || this.is_blocked()) {
          this.sprite_stop()
          await wait(500)
          if (this.state === 'walking') {
            this.state = null
            this.target_point = null
          }
          break
        }
        origin_distance = distance
      }
      else {
        break
      }
      await wait(250)  // 避免while loop 跑太快
    }
    // 移動後，搜尋一下食物（理想是移動中就會邊搜尋，但好像有點耗效能，所以放在這，等走到定點再搜尋食物）
    if (this.scene.foods.length > 0) {
      this.search_food()
    }
  }

  async after_eating() {
    audio_manager.play_audio('sound_effect', 'eat')

    // 紀錄烏龜開心吃飼料
    gtag('event', 'happy_feed')

    const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay))
    this.status.add_satiety(20)
    this.status.add_happiness(1)

    this.target_food.be_eaten()

    // 吃東西可以重置pet_cnt
    this.pet_cnt = 0
    await wait(1500)
    this.state = null
    // 重新搜尋食物
    this.search_food()
  }

  async after_kicking() {
    audio_manager.play_audio('sound_effect', 'kick')

    // 紀錄烏龜不開心吃飼料
    gtag('event', 'angry_feed')
    const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay))
    this.status.add_happiness(-1)

    this.target_food.be_kicked(this.sprite.flipX)

    await wait(500)
    this.state = null
    // 重新搜尋食物
    this.search_food()
  }

  is_blocked() {
    return this.sprite.body && this.sprite.body.blocked.none !== true
  }
}

const MISSION_CHOICES = {
  CLEAR_POOP: 1,
  REACH_100_SATIETY: 2,
  REACH_100_CLEANLINESS: 3,
  REACH_100_HAPPINESS: 4,
  PET_10: 5,
  PET_30: 6,
  PET_50: 7,
  WATCH_1_AD: 8,
  COMPLETE_ALL_MISSIONS: 9
}

const RESET_TIME_OF_DAILY = '04:00'

/**
 * MISSIONS 格式：
 * {
 *  mission_id: {
 *    mission_id,
 *    desc,
 *    goal,  // 目標數量
 *    reward,  // 獎勵金額
 *  }
 * }
 *
 * 若有增減修改 mission_id，請一併更改下方 MISSION_CHOICES
 */
let MISSIONS = {
  [MISSION_CHOICES.CLEAR_POOP]: {
    mission_id: MISSION_CHOICES.CLEAR_POOP,
    desc: '清除烏龜大便',
    goal: 1,
    reward: 30,
  },
  [MISSION_CHOICES.REACH_100_SATIETY]: {
    mission_id: MISSION_CHOICES.REACH_100_SATIETY,
    desc: '讓烏龜飽食度100',
    goal: 1,
    reward: 30,
  },
  [MISSION_CHOICES.REACH_100_CLEANLINESS]: {
    mission_id: MISSION_CHOICES.REACH_100_CLEANLINESS,
    desc: '讓烏龜清潔度100',
    goal: 1,
    reward: 30,
  },
  [MISSION_CHOICES.REACH_100_HAPPINESS]: {
    mission_id: MISSION_CHOICES.REACH_100_HAPPINESS,
    desc: '讓烏龜開心度100',
    goal: 1,
    reward: 30,
  },
  [MISSION_CHOICES.PET_10]: {
    mission_id: MISSION_CHOICES.PET_10,
    desc: '摸烏龜10下',
    goal: 10,
    reward: 10,
  },
  [MISSION_CHOICES.PET_30]: {
    mission_id: MISSION_CHOICES.PET_30,
    desc: '摸烏龜30次',
    goal: 30,
    reward: 20,
  },
  [MISSION_CHOICES.PET_50]: {
    mission_id: MISSION_CHOICES.PET_50,
    desc: '摸烏龜50次',
    goal: 50,
    reward: 30,
  },
  [MISSION_CHOICES.WATCH_1_AD]: {
    mission_id: MISSION_CHOICES.WATCH_1_AD,
    desc: '觀看1次廣告',
    goal: 1,
    reward: 15,
  },
}

const num_of_missions = Object.keys(MISSIONS).length

MISSIONS = {
  ...MISSIONS,
  [MISSION_CHOICES.COMPLETE_ALL_MISSIONS]: {
    mission_id: MISSION_CHOICES.COMPLETE_ALL_MISSIONS,
    desc: '完成所有每日任務',
    goal: num_of_missions,
    reward: 20,
  }
}

export {
  MISSION_CHOICES,
  RESET_TIME_OF_DAILY,
  MISSIONS
}

<template>
  <div class="root">
    <div class="back_ground_layer">
      <img class="sky_bg" :src="sky_image">
    </div>

    <div class="game_layer" :id="containerId" v-show="is_ready"/>

    <div class="weather_overlay_layer">
      <div class="rainy_container">
        <div class="rain front-row"></div>
        <div class="rain back-row"></div>
      </div>
      <div v-show="is_cloudy" class="cloudy_overlay"></div>
      <div v-show="is_night" class="night_overlay"></div>
    </div>

    <div id="UI_root" class="UI_layer">
      <Store
        v-if="store_open"
        :money="money"
        @close_store="store_open = false"
        @add_egg="add_an_egg"
      />

      <Header
        v-show="current_scene && current_scene === 'BathScene'"
        :title="'泡澡'"
        @close="switch_scene('PlayScene')"
      >
      </Header>

      <header class="main_header" :class="current_scene && current_scene === 'BathScene'? 'with_top_header' : ''">

        <!-- leftside header -->
        <!-- profile block: includes profile pic and status bars -->
        <div class="header_left profile_block">
          <!-- profile picture -->
          <div class="profile_pic_block">
            <div class="profile_pic">
              <img v-if="selected_soul && selected_soul.profile_pic_path"
                :src="selected_soul.profile_pic_path"
                alt="profile_pic"
                :class="selected_soul.state === 'egg'? 'egg_img' : 'tortoise_img'"
              >
            </div>
            <div v-if="status" class="survival_day">Day {{ status.survival_days }}</div>
            <!-- 頭像顯示紅圈圈 -->
            <!-- <div
              v-show="life_percentage && life_percentage > 0 && life_percentage <= 100"
              class="progress_circle"
              :class="{ over_half: life_percentage > 50 }"
            >
              <div
                :style="'transform: rotate(' + life_percentage * 3.6 + 'deg);'"
                class="half_circle"
              ></div>
              <div class="half_circle right_side"></div>
            </div> -->
          </div>

          <!-- status block -->
          <div class="status_block">
            <!-- happiness -->
            <div>
              <img src="@/assets/imgs/UI/happiness.png" alt="happiness">
              <div
                v-if="gameInstance && status"
                class="progress_bar"
              >
                <div
                  class="filled_part happiness"
                  :style="'width: ' + validate_status_value(status.happiness) + '%'"
                ></div>
                <span class="progress_text">{{ validate_status_value(status.happiness) }}/{{ status.MAX_OF_STATUS }}</span>
              </div>
              <div
                v-else
                class="progress_bar"
              >
                <span class="progress_text">0/0</span>
              </div>
            </div>
            <!-- satiety -->
            <div>
              <img src="@/assets/imgs/UI/fullness.png" alt="satiety">
              <div
                v-if="gameInstance && status"
                class="progress_bar"
              >
                <div
                  class="filled_part satiety"
                  :style="'width: ' + validate_status_value(status.satiety) + '%'"
                ></div>
                <span class="progress_text">{{ validate_status_value(status.satiety) }}/{{ status.MAX_OF_STATUS }}</span>
              </div>
              <div
                v-else
                class="progress_bar"
              >
                <span class="progress_text">0/0</span>
              </div>
            </div>
            <!-- cleanliness -->
            <div>
              <img src="@/assets/imgs/UI/clean.png" alt="cleanliness">
              <div
                v-if="gameInstance && status"
                class="progress_bar"
              >
                <div
                  class="filled_part cleanliness"
                  :style="'width: ' + validate_status_value(status.cleanliness) + '%'"
                ></div>
                <span class="progress_text">{{ validate_status_value(status.cleanliness) }}/{{ status.MAX_OF_STATUS }}</span>
              </div>
              <div
                v-else
                class="progress_bar"
              >
                <span class="progress_text">0/0</span>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/imgs/UI/header_plant_home.png" class="header_plant">

        <!-- rightside header -->
        <div class="header_right" :style="current_scene && current_scene === 'BathScene'? 'display: none' : ''">
          <!-- money bar -->
          <div class="round_bar money_bar darken">
            <img src="@/assets/imgs/UI/coin.png">
            <span v-if="money">{{ money.amount }}</span>
          </div>

          <!-- weather bar -->
          <div
            class="round_bar weather_bar"
            :class="{on: weather_detail_visible}"
            @click="show_weather_detail()"
            v-click-sound
          >
            <div class="location">
              <img src="@/assets/imgs/UI/map_pin.png" alt="map_pin">
              <span v-if="weather_data && weather_data.district">
                {{ weather_data.district[1] }}
              </span>
              <span v-else>
                --
              </span>
            </div>
            <div>
              <img src="@/assets/imgs/UI/temp.png" alt="temp">
              <span v-if="weather_data && weather_data.tx">
                {{ weather_data.tx }}°C
              </span>
              <span v-else>-- °C</span>
            </div>
            <div>
              <img src="@/assets/imgs/UI/humidity.png" alt="rh">
              <span v-if="weather_data && weather_data.rh">
                {{ weather_data.rh }}%
              </span>
              <span v-else>-- %</span>
            </div>
          </div>
        </div>
      </header>

      <Modal
        :visible.sync="weather_detail_visible"
        :card_style="'weather_detail_card'"
        :mask_required="false"
        closable
        mask_closable
      >
        <template v-slot:header>
          <div class="img_text">
            <img src="@/assets/imgs/UI/map_pin.png" alt="map_pin">
            <span v-if="weather_data && weather_data.district">
              {{ weather_data.district[0] }} {{ weather_data.district[1] }}
            </span>
            <span v-else>--</span>
          </div>
        </template>
        <template v-slot:content>
          <div class="info">
            <div class="img_text">
              <img src="@/assets/imgs/UI/temp.png" alt="temp">
              <span>溫度</span>
              <span v-if="weather_data && weather_data.tx">{{ weather_data.tx }}°C</span>
              <span v-else>-- °C</span>
            </div>
            <div class="img_text">
              <img src="@/assets/imgs/UI/humidity.png" alt="rh">
              <span>濕度</span>
              <span v-if="weather_data && weather_data.rh">{{ weather_data.rh }} %</span>
              <span v-else>-- %</span>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div></div>
        </template>
        <template v-slot:bottom>
          <div class="footer">
            <div class="updated_time">更新時間：{{ weather_data.dtime }}</div>
            <!-- GPS定位按鈕 -->
            <button
              class="locate_btn"
              @click="update_location"
              :disabled="weather_data_loading"
            >
              <div
                v-if="weather_data_loading"
                class="loader_rotate"
              >
              </div>
              <i
                v-else
                class="icon-position_arrow"
                style="line-height: 20px;"
              >
              </i>
            </button>
          </div>
        </template>
      </Modal>

      <div
        v-show="current_scene === 'PlayScene'"
        class="float_btn_container"
      >
        <div v-show="selected_soul">
          <!-- 烏龜資本資訊 -->
          <div
            v-if="selected_soul && status.has_birth"
            class="float_icon_btn"
            @click="view_soul_info()"
            v-click-sound
          >
            <img src="@/assets/imgs/UI/info.png" alt="take_bath" style="width: 20px; height: 20px">
          </div>

          <!-- 送養 -->
          <div
            class="float_icon_btn"
            @click="launch_giveup_confirm()"
            v-click-sound
          >
            <img src="@/assets/imgs/UI/bye-bye.png" alt="take_bath" style="width: 20px; height: 20px">
          </div>
        </div>

        <div class="float_right">
          <!-- 任務 -->
          <div
            class="float_img_btn"
            @click="view_mission_board()"
            v-click-sound
          >
            <div v-show="mission_notification" class="notification_badge"></div>
            <img
              src="@/assets/imgs/UI/task.png"
              alt="mission"
            >
          </div>
          <!-- 配置 -->
          <div
            class="float_img_btn"
            @click="configuration_visible = true"
            v-click-sound
          >
            <img
              src="@/assets/imgs/UI/set_up.png"
              alt="configuration"
            >
          </div>
        </div>
      </div>

      <footer v-show="!current_scene || current_scene === 'PlayScene'">
        <div class="footer_btn_container">
          <div
            class="footer_btn"
            @click="view_place()"
            v-click-sound
          >
            <div>
              <img src="@/assets/imgs/UI/place.png" alt="place">
              <span>飼育園區</span>
            </div>
          </div>
          <div
            class="footer_btn"
            @click="switch_scene('BathScene')"
            v-click-sound
          >
            <div>
              <img src="@/assets/imgs/UI/bath.png" alt="take_bath">
              <span>泡澡</span>
            </div>
          </div>
          <div
            class="footer_btn"
            @click="view_collection_book()"
            v-click-sound
          >
            <div>
              <img src="@/assets/imgs/UI/collection_book.png" alt="collection">
              <span>圖鑑</span>
              <div v-show="collection_notification" class="notification_badge"></div>
            </div>
          </div>
          <div
            class="footer_btn"
            @click="view_store()"
            v-click-sound
          >
            <div>
              <img src="@/assets/imgs/UI/shopping_cart.png" alt="store">
              <span>商店</span>
            </div>
          </div>
          <div
            class="footer_btn"
            @click="set_model_visible(true)"
            v-click-sound
          >
            <div>
              <img src="@/assets/imgs/UI/feedback.png" alt="feedback">
              <span>意見回饋</span>
            </div>
          </div>
        </div>
      </footer>

      <!-- feedback dialog -->
      <Modal
        :visible.sync="info_model_visible"
        title="相關意見"
        @confirm="feedback_submit"
        card_style="feedback_card"
        ok_text="送出"
        closable
      >
        <template v-slot:content>
          <div>
            <div>這是我們做的第一款遊戲，仍有許多地方需要改進，希望您能提供給我們寶貴的意見，一起讓遊戲變得更好！</div>
            <div>如有任何問題，歡迎至
              <a class="link" target="_blank" href="https://www.facebook.com/PocketTortoise">口袋烏龜粉絲頁</a>
            詢問． ( 別忘了按讚追蹤喔❤️ )</div>
            <div>填寫詳細遊戲回饋問卷👇</div>
            <a
              class="wood_btn survey_btn"
              href="https://www.surveycake.com/s/9Yz3P"
              target="_blank"
              @click="click_surveycake()"
            >
              👉 點此進入詳細遊戲回饋問卷 👈
            </a>
            <div>或是想給我們簡短的回饋，也可以直接在下方輸入後送出！</div>
            <textarea
              class="input--base"
              rows="10"
              placeholder="請輸入內容"
              v-model="feedback_state.feedback"
            >
            </textarea>
          </div>
        </template>
      </Modal>

      <!-- collection book -->
      <CollectionBook
        v-show="collection_book_visible"
        :collection_book_visible.sync="collection_book_visible"
        :money="money"
      />

      <!-- mission board -->
      <MissionBoard
        :visible.sync="mission_board_visible"
        :money="money"
      />

      <!-- configuration -->
      <Configuration
        :visible.sync="configuration_visible"
      />

      <PlacePage
        :visible.sync="place_page_visible"
        :money="money"
      >
      </PlacePage>

      <!-- login bonus -->
      <Modal
        :visible.sync="login_bonus_visible"
        title="每日登入獎勵"
        :money_amount="'30'"
        content_text="獲得烏龜幣"
        @confirm="get_login_bonus"
      >
      </Modal>

      <Modal
        :visible.sync="rename_visible"
        title="修改暱稱"
        @confirm="rename_tortoise"
        cancel_btn_required
      >
        <template v-slot:content>
          <div>
            <input class="input--base" v-model="tortoise_name" placeholder="請輸入最多10字元" maxlength="10">
          </div>
        </template>
      </Modal>

      <Modal
        :visible.sync="change_place_modal_visible"
        title="切換飼育園區"
        @confirm="change_tortoise_place"
        cancel_btn_required
      >
        <template v-slot:content>
          <div class="container_select--base">
            <select
              v-model="tortoise_place_idx"
              class="input--base"
            >
              <option
                v-for="(place, idx) in places"
                :key="'place_option_' + idx"
                :value="idx"
                :disabled="place_manager.is_place_full(idx)"
              >
                飼育園區{{ idx+1 }}
              </option>
            </select>
            <i class="icon-arrow_down"></i>
          </div>
        </template>
      </Modal>

      <Modal
        :visible.sync="soul_info_modal_visible"
        :title="status ? '基本資訊' : '--'"
        mask_closable
        closable
        :mask_required="false"
      >
        <template v-slot:content>
          <div class="base_info_content" style="text-align: left">
            <img :src="selected_soul? selected_soul.profile_pic_path : ''" class="base_info_img">
            <div>
              <div>
                暱稱：{{ selected_soul ? selected_soul.name : '--'}}
              </div>
              <div
                class="icon-edit-1 wood_btn edit_btn"
                @click="tortoise_naming"
                v-click-sound
              >
              </div>
            </div>
            <div>
              <div>
                位置：飼育園區{{ selected_soul ? selected_soul.place_idx+1 : '--'}}
              </div>
              <div
                class="icon-edit-1 wood_btn edit_btn"
                @click="launch_change_place_modal"
                v-click-sound
              >
              </div>
            </div>
            <div>
              誕生時間：{{ selected_soul_birth }}
            </div>
            <div>
              飼養天數：{{status ? status.survival_days + ' 天' : '--'}}
            </div>
            <!-- <div>
              成龜時間：{{ selected_soul_pass_time }}
            </div> -->
          </div>
        </template>
        <template v-slot:footer>
          <div></div>
        </template>
      </Modal>

      <!-- heal confirm -->
      <Modal
        :visible.sync="heal_confirm_visible"
        title="治療生病烏龜"
        :content_img="sick_soul? sick_soul.profile_pic_path : null"
        closable
      >
        <template v-slot:content_text>
          <div>
            您的烏龜生病了...
          </div>
          <div>
            可花費烏龜幣或是觀看廣告來治癒
          </div>
        </template>
        <template v-slot:footer>
          <div class="double_confirm_footer">
            <button
              class="wood_btn"
              v-click-sound
              @click="pay_to_heal"
            >
              <img src="@/assets/imgs/UI/coin.png" alt="">
              1000
            </button>
            <button
              class="wood_btn gold_btn"
              v-click-sound
              @click="watch_ad_to_heal"
            >
              <i class="icon-film"></i>
              免費治療
            </button>
          </div>
        </template>
      </Modal>
    </div>

    <Loading
      :end_loading="weather_state.obs_data_received"
      @finish_loading_animated="finish_loading_animated"
    >
    </Loading>
  </div>
</template>

<script>
import { WeatherVState } from '@/state/Weather'
import { FeedbackVState } from '@/state/Feedback'
import Loading from '@/components/Loading'
import Store from '@/components/Store'
import CollectionBook from '@/components/CollectionBook'
import MissionBoard from '@/components/MissionBoard'
import Configuration from '@/components/Configuration'
import Header from '@/components/Header'
import PlacePage from '@/components/PlacePage.vue'

import Money from '@/components/money.js'
import GM from '@/components/game_manager'
import collection_manager from '@/components/collection_manager'
import mission_manager from '@/components/mission_manager'
import place_manager from '@/components/place_manager'
import { reward_ad_manager } from '@/libs/ad'
import { MISSION_CHOICES, RESET_TIME_OF_DAILY } from '@/libs/missions'

export default {
  name: 'Game',

  components: {
    Loading,
    Store,
    CollectionBook,
    MissionBoard,
    Configuration,
    Header,
    PlacePage
},

  data() {
    return {
      weather_state: WeatherVState,
      feedback_state: FeedbackVState,

      info_model_visible: false,
      weather_detail_visible: false,
      login_bonus_visible: false,
      collection_book_visible: false,
      store_open: false,
      place_page_visible: false,
      mission_board_visible: false,
      configuration_visible: false,
      is_ready: false,
      soul_info_modal_visible: false,
      heal_confirm_visible: false,
      rename_visible: false,
      change_place_modal_visible: false,

      gameInstance: null,
      GM: GM,
      collection_manager: collection_manager,
      mission_manager: mission_manager,
      place_manager: place_manager,
      containerId: 'game-container',

      image: {
        daytime_sunny: require('@/assets/imgs/scene/daytime_sunny.png'),
        night_moon: require('@/assets/imgs/scene/night_moon.png'),
        cloudy: require('@/assets/imgs/scene/cloudy.png'),
        rainy: require('@/assets/imgs/scene/rainy.png'),
        hole: require('@/assets/imgs/goods/hole.png'),
      },
      current_hour: null,
      current_timer: null,

      money: null,
      sick_soul: null,
      tortoise_name: null,
      tortoise_place_idx: null,
    }
  },

  computed: {
    weather_data_loading() {
      return this.weather_state.obs_data_loading
    },

    weather_data() {
      let obs_data = this.weather_state.obs_data
      if (!obs_data || obs_data === {}) {
        return null
      }

      obs_data.dtime = obs_data.dtime ? new Date(obs_data.dtime).toLST().strftime('%Y-%m-%d %H:%M') : '--'
      return obs_data
    },

    sky_image() {
      if (this.weather_state.obs_data && this.weather_state.obs_data.wx === "陰天") {
        return this.image.cloudy
      }
      else if (this.weather_state.obs_data && this.weather_state.obs_data.wx === "雨天") {
        return this.image.rainy
      }
      else {
        // 若沒有 obs_data 一律顯示晴天天空
        if (this.is_night) {
          return this.image.night_moon
        }
        return this.image.daytime_sunny
      }
    },

    is_cloudy() {
      if (this.weather_state.obs_data && (this.weather_state.obs_data.wx === "陰天" || this.weather_state.obs_data.wx === "雨天")) {
        return true
      }
      return false
    },

    is_night() {
      if (this.current_hour < 6 || this.current_hour > 17) {
        return true
      }
      return false
    },

    // life_percentage() {
    //   if (this.status) {
    //     let percentage = (this.status.life / this.status.LIFE_SPAN) * 100
    //     return percentage >= 100 ? 100 : percentage
    //   }
    // },

    current_scene() {
      if (GM) {
        return GM.current_scene_key
      }
    },

    does_tortoise_exist() {
      return GM && GM.does_tortoise_exist
    },

    selected_soul() {
      if (GM) {
        return GM.selected_soul
      }
    },

    selected_soul_birth() {
      if (this.status?.time_of_birth) {
        return this.status.time_of_birth.strftime("%Y-%m-%d %H:%M")
      }
      return '--'
    },

    // selected_soul_pass_time() {
    //   if (this.status?.time_of_birth) {
    //     return this.status.time_of_birth.addDays(7).strftime("%Y-%m-%d %H:%M")
    //   }
    //   return '--'
    // },

    status() {
      if (this.selected_soul) {
        return this.selected_soul.status
      }
    },

    collection_notification() {
      return this.collection_manager.reward_exists
    },

    mission_notification() {
      return this.mission_manager.notification_badge_exists
    },

    places() {
      return this.place_manager.places
    }
  },

  methods: {
    update_location() {
      gtag('event', 'update_location')
      this.weather_state.update_location()
    },

    add_an_egg(variety_id, place_idx) {
      if (GM) {
        GM.add_an_egg(variety_id, place_idx)
      }
    },

    switch_scene(scene_key) {
      GM.switch_scene(scene_key)
    },

    view_place() {
      this.place_page_visible = true
      gtag('event', 'view_place')
    },

    view_store() {
      this.store_open = true
      gtag('event', 'view_store')
    },

    view_collection_book() {
      this.collection_book_visible = true
      gtag('event', 'view_collection_book')
    },

    view_mission_board() {
      this.mission_board_visible = true
      gtag('event', 'view_mission_board')
    },

    view_soul_info() {
      this.soul_info_modal_visible = true
      gtag('event', 'view_soul_info')
    },

    launch_giveup_confirm() {
      let giveup_soul = this.selected_soul
      this.$pt_confirm({
        title: "確定要送養嗎",
        content: "送養後烏龜會離開您身邊再也見不到，不會得到任何獎勵"
      })
      .then(() => {
        gtag('event', 'giveup_soul', {
          variety_id: this.selected_soul.variety_id
        })
        giveup_soul.eliminate()
      })
      .catch(() => {})
    },

    weather_value(value) {
      const missing_value = -999
      if (value === null || value === undefined || value === missing_value) {
        return '--'
      }
      return value
    },

    feedback_submit(ok) {
      if (ok) {
        this.feedback_state.save_feedback()
        this.feedback_state.clear()
      }
    },

    remove_all_foods() {
      gtag('event', 'clear_food')
      this.gameInstance.scene.scenes[1].remove_all_foods()
    },

    show_weather_detail() {
      this.weather_detail_visible = !this.weather_detail_visible
      if (this.weather_detail_visible) {
        gtag('event', 'show_weather_detail')
      }
    },

    click_surveycake() {
      gtag('event', 'click_surveycake')
    },

    set_model_visible(visible) {
      // 判斷意見回饋對話框是否打開，是的話，不能點到playscene，防止食物出現在地上或點到烏龜
      this.info_model_visible = visible

      if (visible) {
        gtag('event', 'click_feedback')
      }
    },

    finish_loading_animated() {
      // 紀錄loading完後成功進入遊戲
      let finish_loading_time = new Date()
      let loading_time = finish_loading_time.diffSeconds(OPEN_APP_TIME)
      gtag('event', 'end_loading', {
        'loading_time': loading_time
      })
    },

    validate_status_value(val) {
      // 使用雙等號來同時判斷 null 和 undefined
      if (val == null) {
        return 0
      }
      return val
    },

    get_login_bonus() {
      let login_time = new Date().toLST()
      localStorage.setItem('last_login', login_time)

      this.money.earn(30)
    },

    check_last_login() {
      let last_login_date = localStorage.getItem('last_login')
      if (last_login_date) {
        last_login_date = new Date(last_login_date)
        if (last_login_date < new Date().zero_hour()) {
          this.login_bonus_visible = true
        }
        else {
          // 沒觸發登入獎勵，直接紀錄本次登入時間
          localStorage.setItem('last_login', new Date().toLST())
        }

        this.is_ready = true
      }
      else {
        this.login_bonus_visible = true
        this.is_ready = true

        // 為了下面重置時間的處理
        last_login_date = new Date()
      }

      // 處理任務重置時間
      let time_to_reset_missions = Date.strptime(RESET_TIME_OF_DAILY, '%H:%M')
      if (time_to_reset_missions < last_login_date) {
        time_to_reset_missions = time_to_reset_missions.addDays(1)
      }
      mission_manager.set_time_to_reset_missions(time_to_reset_missions)
    },

    init_money() {
      this.money = new Money()
    },

    pay_to_heal() {
      if (this.money.pay(1000)) {
        gtag('event', 'pay_to_heal')
        this.heal()
      }
    },

    tortoise_naming() {
      this.soul_info_modal_visible = false
      this.rename_visible = true
    },

    launch_change_place_modal() {
      this.soul_info_modal_visible = false
      this.change_place_modal_visible = true
      this.tortoise_place_idx = this.selected_soul.place_idx
    },

    rename_tortoise(ok) {
      if (ok) {
        gtag('event', 'tortoise_rename')
        this.selected_soul.set_name(this.tortoise_name)
      }
      this.soul_info_modal_visible = true
      this.tortoise_name = null
    },

    change_tortoise_place(ok) {
      if (ok) {
        gtag('event', 'change_tortoise_place')
        this.selected_soul.set_place_idx(this.tortoise_place_idx)
      }
      else {
        this.soul_info_modal_visible = true
      }
      this.tortoise_place_idx = null
    },

    watch_ad_to_heal() {
      gtag('event', 'watch_ad_to_heal')
      reward_ad_manager.show_ad(() => {
        // rewarded
        this.heal()
      }, () => {
        // 播放廣告失敗觸發
        this.$pt_alert({
          title: "錯誤",
          content: "廣告載入失敗，請稍後再試"
        })
      })
    },

    heal() {
      this.sick_soul.heal()
      this.$pt_alert({
        title: "烏龜已治癒！",
        html_content: "您的烏龜已恢復健康！<br>要天天上來照顧，才會頭好壯壯喔",
        content_img: this.sick_soul.profile_pic_path
      })
      this.sick_soul = null
      this.heal_confirm_visible = false
    }
  },

  watch: {

  },

  created() {
    this.init_money()
    this.check_last_login()

    /** 以下是想清除用不到的localStorage item，不確定要不要用，但之後記得刪掉 */
    localStorage.removeItem("birth")
    localStorage.removeItem("time_of_last_decrease")
    localStorage.removeItem("time_of_last_routine_decrease")
    localStorage.removeItem("time_of_last_dirty_decrease")
    /** */

    GM.$on("dead", (soul) => {
      // 加上 nextTick 因為要等 soul.status 生成
      this.$nextTick(() => {
        this.$pt_alert({
          title: "悲報",
          content: "您的小龜病死了",
          content_img: soul.profile_pic_path,
          money_amount: "- 30"
        })
        .then(() => {
          gtag('event', 'tortoise_dead')
          this.money.pay(30, false)
          collection_manager.add_cnt(soul.variety_id, 'fail_cnt', 1)
          soul.eliminate()
        })
      })
    })

    GM.$on("click_heal_popup", (soul) => {
      gtag('event', 'click_heal_popup')
      this.sick_soul = soul
      this.$nextTick(() => {
        this.heal_confirm_visible = true
      })
    })
  },

  async mounted() {
    // launch game
    const game = await import(/* webpackChunkName: "game" */ '@/components/game')
    this.$nextTick(() => {
      // 讓 UI 層的操作不會穿透到遊戲層
      const UI_root = document.getElementById('UI_root');
      for (const eventName of ['mouseup','mousedown', 'touchstart', 'touchmove', 'touchend', 'touchcancel']){
        UI_root.addEventListener(eventName, e => e.stopPropagation())
      }

      this.gameInstance = game.launch(this.containerId)
      GM.init(this.gameInstance)

    })

    // 為了可以即時判斷日夜，這邊每秒去取得一次現在幾點
    this.current_timer = setInterval(() => {
      this.current_hour = new Date().getHours()
    }, 1000)


    window.onbeforeunload = function() {
      gtag('event', 'close_app')
    }
  },

  beforeDestroy() {
    if (this.current_timer) {
      clearInterval(this.current_timer)
    }
    if (GM.world_timer) clearInterval(GM.world_timer)
  },

  destroyed() {
    this.gameInstance.destroy(false)
  }
}
</script>

<style lang="scss" scoped>
.root {
  @include size;
}

.back_ground_layer {
  @include full_size;
  @include background_layer;
}
.sky_bg {
  @include abs_position(0, 0);
  width: 100%;
  opacity: 1;
  display: block;
}

.game_layer {
  @include game_layer;
  width: 100%;
  height: unquote('min(100%, 100% - constant(safe-area-inset-bottom))');
  height: unquote('min(100%, 100% - env(safe-area-inset-bottom))');
}

.weather_overlay_layer {
  @include full_size;
  @include game_layer(1);  // 在遊戲上面一層
  pointer-events: none;  // 避免phaser的pointer事件觸發多次

  > .cloudy_overlay {
    @include full_size;
    background-color: rgba(105, 113, 122, 0.3);
  }

  > .night_overlay {
    @include full_size;
    background-color: rgba(47, 50, 114, 0.4);
  }
}

.UI_layer {
  @include full_size;
  @include UI_layer;
  pointer-events: none;  // 不加的話會擋住拖曳的操作

  > * {
    pointer-events: auto;
  }
}

header {
  position: relative;
}


/** header */
.main_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  > .header_plant {
    @include abs_position;
    height: 108%;
  }

  &.with_top_header {
    margin: $small 0;
  }
}

.header_left {
  position: relative;
  flex-grow: 1;
  min-width: 190px;
  max-width: 300px;
}

.header_right {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 $small;
}

.profile_block {
  @include wood_bg;
  border-radius: 0 50rem 50rem 0;
  padding: 2px;
  padding-right: $large; //$large:24px
  display: flex;

  font-size: 14px;
  font-weight: bold;
  color: rgb(255, 248, 237);
  text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

  box-shadow: 1px 2px 0px rgb(150, 122, 80);
}

.profile_pic_block {
  position: relative;
  margin: 2px 4px;

  // $profile_pic-size: 56px;
  // .progress_circle {
  //   @include size;
  //   @include abs_position(0, 0);

  //   // not half
  //   clip: rect(0, $profile_pic-size, $profile_pic-size, $profile_pic-size / 2);
  //   .half_circle.right_side {
  //     display: none;
  //   }

  //   // over half
  //   &.over_half {
  //     clip: auto;
  //     .half_circle.right_side {
  //       display: block;
  //       transform: rotate(180deg);
  //     }
  //   }

  //   .half_circle {
  //     @include size;
  //     @include abs_position(0, 0);
  //     border: 3px solid rgb(253, 65, 65);
  //     border-radius: 50%;
  //     clip: rect(0, $profile_pic-size / 2, $profile_pic-size, 0);
  //   }
  // }
}

.survival_day {
  @include abs_position($left: 50%, $bottom: -6px);
  transform: translateX(-50%);
  white-space: nowrap;
  font-weight: bold;
  color: rgb(255, 248, 237);
  text-shadow: 0 1px 1px rgba(110, 73, 46, 0.5);
  z-index: 1;
}

.status_block {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;

    > img {
      @include size(16px);
      margin: 2px;
    }
  }
}

.money_bar {
  display: flex;
  justify-content: flex-start;

  font-size: 14px;
  font-weight: bold;
  color: rgb(255, 248, 237);
  text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

  > img {
    height: 20px;
    width: 20px;
    margin: 2px;
  }

  > span {
    @include abs_position(50%, 50%);
    transform: translateX(50%) translateY(-50%);
  }
}

.weather_bar {
  display: flex;
  justify-content: space-around;
  cursor: pointer;

  font-size: 12px;
  font-weight: bold;
  color: rgb(255, 248, 237);
  text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

  img {
    width: 16px;
    margin-right: 2px;
  }

  .location {
    text-shadow: none;
    color: rgb(255, 248, 237);
    text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);
  }

  @media screen and (max-width: 380px) {
    .location {
      display: none;
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin: 2px;
  }

  &.on {
    @include wood_bg(true);
  }
}

.weather_detail_card {
  .img_text {
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 24px;
      margin-right: $ex-small; //$ex-small:4px
    }
  }

  .modal-content {
    margin: 0;
  }

  .info {
    text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

    > .img_text {
      display: flex;
      align-items: center;

      > span:last-child {
        font-size: 20px;
        margin-left: $medium; //$medium:16px
      }
    }
  }

  .footer {
    display: flex;
    padding: 0 $medium $small $medium;
    justify-content: space-between;
    align-items: center;
    line-height: 22px;

    .updated_time {
      font-size: 12px;
      font-weight: 500;
      color: rgb(100, 77, 46);
    }

    button {
      border-radius: $small;
      border-style: none;
      --tw-text-opacity: 1;
      color: rgba(246, 180, 85, var(--tw-text-opacity));
    }

    .locate_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: rgb(246, 180, 85);
      height: 24px;
      width: 40px;
    }

    .loader_rotate {
      width: 1.6rem;
      height: 1.6rem;
      border: .3rem solid $color-black--5;
      border-top: .3rem solid $color-gold;
      border-radius: 50%;
      -webkit-animation: spin 1.5s linear infinite; /* Safari */
      -moz-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
    }
  }
}
/** end of header */

.float_btn_container {
  position: relative;
  margin-top: $medium; //$medium:16px
  display: flex;
  justify-content: space-between;

  > .float_right {
    @include abs_position(0, 0);
  }
}

.float_icon_btn {
  @include size(36px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $small; //$small:8px
  background-color: rgb(150, 119, 81);
  border-radius: 50%;
  border: 3px solid #cbb08c;
  cursor: pointer;
}

.float_img_btn {
  margin: $small; //$small:8px
  cursor: pointer;
  position: relative;

  > img {
    @include size(44px);
  }
}

/** footer */
footer {
  @include wood_bg;
  @include abs_position($bottom: 0);
  width: 100%;
  height: unquote('max(42px, constant(safe-area-inset-bottom) + 42px)');
  height: unquote('max(42px, env(safe-area-inset-bottom) + 42px)');

  .footer_btn_container {
    transform: translateY(-20px);
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
  }
}

.base_info_content {
  display: flex;
  flex-direction: column;
  gap: $small;
  margin: 0 $ex-small;

  > .base_info_img {
    width: 80px;
    height: 60px;
    align-self: center;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }
}

.edit_btn {
  font-size: 12px;
  border-radius: 50rem;
  border-width: 2px;
  padding: 0 $small;
  margin: 0;
}

.name_input {
  height: 40px;
  border-radius: 8px;
  border: 2px solid rgb(203, 176, 140);
  background: rgb(255, 248, 237);
}

.footer_btn {
  @include wood_bg;
  @include size(56px);
  border-radius: 50%;
  cursor: pointer;

  //這邊是為了讓btn融入背景感覺成一體的樣子
  background-position-y: 20px;
  background-repeat: repeat;
  background-size: auto 75%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-8px);

    > img {
      width: 48px;
    }

    > span {
      font-size: 12px;
      font-weight: bold;
      color: rgb(255, 248, 237);
      text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);
    }
  }

  .ban {
    @include size(66px);

    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    color: white;
    cursor: no-drop;

    position: absolute;
    display: flex;
    align-items: center;
  }
}

/** end of footer */

.bouns_alert {
  @include size;
  @include abs_position(0, 0);
  background-color: rgba(black, 0.6);
  display: flex;
  align-items: center;
  z-index: 998;

  > .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;

    > div, > button {
      @include wood_bg;
      border: 4px solid rgb(203, 176, 140);
      padding: $small $medium; //$medium:16px
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);

      font-weight: bold;
      font-size: 20px;
      color: rgb(255, 248, 237);
      text-shadow: 0 1px 1px rgba(110, 73, 46, 0.5);
    }

    > div {
      border-radius: $medium $medium 0 0; //$medium:16px
      border-bottom: none;
    }

    > span {
      @include wood_bg;
      padding: $small $medium;
      font-size: 20px;
      background-color: rgb(203, 176, 140);
      border-radius: $medium; //$medium:16px
    }

    > button {
      @include wood_bg;
      border-radius: $small; //$small:8px
      margin: $medium; //$medium:16px
    }
  }
}
</style>

<style lang="scss">
.feedback_card {
  width: calc(100% - 16px) !important;
  max-width: 480px;
  height: 90%;

  .link {
    color: #2d38d4;
  }

  .survey_btn {
    text-decoration: none;
    font-size: 14px;
  }

  .modal-content {
    text-align: left;
    margin-bottom: 0;
  }

  .input--base {
    margin-bottom: 0;
  }
}
</style>

import { MISSIONS, MISSION_CHOICES } from "@/libs/missions"

class MissionManager {
  constructor() {
    this.time_to_reset_missions = null
    this.init_mission_data()
  }

  get notification_badge_exists() {
    for (let mission of this.mission_list) {
      if (mission.status === 1) {
        return true
      }
    }
    return false
  }

  get mission_list() {
    let _list = []
    for (let mission_id in MISSIONS) {
      //  處理 status
      let status = 0 // 1: 可領取 | 2: 進行中 | 3: 等待重置中
      let completed = this.data[mission_id].progress >= MISSIONS[mission_id].goal
      let received = Boolean(this.data[mission_id].reward_receive_at)
      if (completed) {
        status = 1
      }
      else if (received) {
        status = 3
      }
      else {
        status = 2
      }

      _list.push({
        ...MISSIONS[mission_id],
        ...this.data[mission_id],
        status: status,
        progress_ratio: this.data[mission_id].progress / MISSIONS[mission_id].goal
      })
    }
    return _list
  }

  init_mission_data() {
    let record = JSON.parse(localStorage.getItem("mission"))
    if (!record) record = {}

    // 透過任務數量判斷 localStorage 內的任務資料是否是舊的，如果是，就全部清掉。（為 1.1.1 升級至 1.2.0 版本設計）
    if (Object.keys(record).length !== Object.keys(MISSIONS).length) {
      record = {}
    }

    for (let mission_id in MISSIONS) {
      record[mission_id] = record[mission_id] || {
        progress: 0,
        reward_receive_at: null
      }

      if (record[mission_id].reward_receive_at) {
        record[mission_id].reward_receive_at = new Date(record[mission_id].reward_receive_at)
      }
    }

    this.data = record
    this.save()
  }

  // 由 Main.vue 確認 last_login 時呼叫
  set_time_to_reset_missions(time) {
    this.time_to_reset_missions = time
  }

  // action: 'add' | 'set'
  make_progress(mission_ids, value=1, action='add') {
    let need_to_save = false

    if (!(mission_ids instanceof Array)) {
      mission_ids = [mission_ids]
    }

    for (let mission_id of mission_ids) {
      if (!this.data.hasOwnProperty(mission_id)) {
        console.error(`mission_id ${mission_id} is not in mission list`)
        continue
      }

      const mission_info = MISSIONS[mission_id]

      // 若此任務已完成
      if (this.data[mission_id].progress >= mission_info.goal) continue

      // 若此任務還在等重置中
      if (this.data[mission_id].reward_receive_at) continue

      if (action === 'add') {
        this.data[mission_id].progress += value
      }
      else {  // 'set'
        this.data[mission_id].progress = value
      }
      need_to_save = true

      // 紀錄玩家完成任務
      if (this.data[mission_id].progress >= mission_info.goal) {
        gtag('event', 'complete_mission', {
          'mission_id': mission_id
        })
      }
    }

    if (need_to_save) {
      this.save()
    }
  }

  receive_reward(mission_id) {
    // 完成任務的任務
    this.make_progress(MISSION_CHOICES.COMPLETE_ALL_MISSIONS)

    // 紀錄玩家領取獎勵
    gtag('event', 'receive_mission_reward', {
      'mission_id': mission_id
    })

    this.data[mission_id].reward_receive_at = new Date()
    this.data[mission_id].progress = 0
    this.save()
  }


  reset_mission(mission_id) {
    this.data[mission_id].progress = 0
    this.data[mission_id].reward_receive_at = null
  }

  reset_daily_missions() {
    for (let mission_id in this.data) {
      this.reset_mission(mission_id)
    }
    if (this.time_to_reset_missions) {
      this.time_to_reset_missions = this.time_to_reset_missions.addDays(1)
    }
    this.save()
  }

  save() {
    localStorage.setItem('mission', JSON.stringify(this.data))
  }
}

const mission_manager = new MissionManager()

export default mission_manager

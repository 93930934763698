
<template>
  <div class="store">
    <Header
      :title="'商店'"
      @close="close_store"
    >
      <template v-slot:right_content>
        <div class="money">
          <img src="@/assets/imgs/UI/coin.png">
          <span>{{ money.amount }}</span>
        </div>
      </template>
    </Header>
    <main
      class="main"
    >
      <div>
        <div class="store_item_block">
          <div
            class="item_content"
            @click="launch_free_tortoise_modal"
            v-click-sound
          >
            <div class="item">
              <img src="@/assets/imgs/tortoise_stand.png">
              <img class="star" src="@/assets/imgs/UI/star.png">
            </div>
            <div class="free">
              免費索取！
            </div>
          </div>
          <img src="@/assets/imgs/UI/product_board.png">
        </div>
        <div class="store_item_block">
          <div
            :class="['item_content']"
            @click="launch_egg_modal"
            v-click-sound
          >
            <img src="@/assets/imgs/egg_shop.png">
            <div class="price">
              <img src="@/assets/imgs/UI/coin.png">
              <span>{{ egg_price }}</span>
            </div>
          </div>
          <img src="@/assets/imgs/UI/product_board.png">
        </div>
      </div>
    </main>

    <Modal
      :visible.sync="egg_modal_visible"
      title="確認購買"
      cancel_btn_required
      :content_img="require('@/assets/imgs/egg_shop.png')"
      :money_amount="egg_price.toString()"
      @confirm="buy_egg"
    >
      <template v-slot:content_text>
        <div>
          孵出不同星等的烏龜機率：
        </div>
        <div class="star_container">
          <div>
            <div>
              <i class="icon-star"></i>
            </div>
            <div>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
            </div>
            <div>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
            </div>
          </div>
          <div>
            <div>{{ varieties_rate['1'] }}%</div>
            <div>{{ varieties_rate['2'] }}%</div>
            <div>{{ varieties_rate['3'] }}%</div>
          </div>
        </div>
      </template>
    </Modal>

    <Modal
      :visible.sync="free_tortoise_modal_visible"
      title="看廣告獲得橘色烏龜"
      cancel_btn_required
      :content_img="require('@/assets/imgs/tortoise/PT_stand_up_normal.png')"
      @confirm="get_free_tortoise"
      content_text="將會開啟廣告，觀看完畢可獲得橘色烏龜"
    >
    </Modal>

    <Modal
      :visible.sync="place_choose_modal_visible"
      title="請選擇誕生位置"
      :content_img="bought_item === 'egg'? require('@/assets/imgs/egg.png') : require('@/assets/imgs/tortoise/PT_stand_up_normal.png')"
      @confirm="add_soul_to_place"
    >
      <template v-slot:content_text>
        <div class="container_select--base">
          <select
            v-model="selected_place_idx"
            class="input--base"
          >
            <option
              v-for="(place, idx) in place_manager.places"
              :key="'place_option_' + idx"
              :value="idx"
              :disabled="place_manager.is_place_full(idx)"
            >
              飼育園區{{ idx+1 }}
            </option>
          </select>
          <i class="icon-arrow_down"></i>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { VARIETIES } from '@/libs/varieties'
import GM from '@/components/game_manager'
import mission_manager from '@/components/mission_manager'
import place_manager from '@/components/place_manager'
import { MISSION_CHOICES } from '@/libs/missions'
import { reward_ad_manager } from '@/libs/ad'

export default {
  name: 'Store',

  components: {
    Header
  },

  props: {
    money: {
      type: Object
    }
  },

  data() {
    return {
      // key: 星等, value: 總機率
      varieties_rate: {
        1: 80,
        2: 15,
        3: 5
      },
      ads: [
        'https://typhoon.tw/', 'https://fishing.weathermap.tw/',
        'https://play.google.com/store/apps/details?id=cb.frog',
        'https://www.facebook.com/PocketTortoise', 'https://www.facebook.com/FishingWeatherMap'
      ],
      place_manager: place_manager,
      egg_modal_visible: false,
      free_tortoise_modal_visible: false,
      place_choose_modal_visible: false,
      bought_item: 'egg',  // 'egg' | 'free_tortoise'
      selected_place_idx: null,
      egg_price: 150
    }
  },

  computed: {

  },

  methods: {
    close_store() {
      this.$emit('close_store')
    },

    launch_egg_modal() {
      this.egg_modal_visible = true
    },

    launch_free_tortoise_modal() {
      this.free_tortoise_modal_visible = true
    },

    // 確認園區空間是否足夠，true: 足夠
    check_place_available() {
      let every_place_is_full = true
      for (let [idx, place] of place_manager.places.entries()) {
        if (!place_manager.is_place_full(idx)) {
          every_place_is_full = false
          this.selected_place_idx = idx  // 選擇園區的選項設為有空位的第一筆
          break
        }
      }

      if (every_place_is_full) {
        gtag('event', 'tortoise_full')
        this.$pt_alert({
          title: '無法購買',
          html_content: '飼育園區皆已額滿'
        })
      }

      return !every_place_is_full
    },

    buy_egg(ok) {
      if (ok) {
        // 確認餘額
        if (this.money.check_balance_enough(this.egg_price)) {
          // 確認園區空間
          if (this.check_place_available()) {
            // 付錢並增加蛋（加 if 是因為付錢時會再判斷一次餘額是否足夠）
            if (this.money.pay(this.egg_price)) {
              this.bought_item = 'egg'
              this.place_choose_modal_visible = true
            }
          }
        }
      }
    },

    get_free_tortoise(ok) {
      if (ok) {
        // 確認園區空間
        if (this.check_place_available()) {
          reward_ad_manager.show_ad(() => {
            // 看完廣告後觸發
            this.bought_item = 'free_tortoise'
            this.place_choose_modal_visible = true

            // 看廣告的任務
            mission_manager.make_progress(MISSION_CHOICES.WATCH_1_AD)
          }, () => {
            // 播放廣告失敗觸發
            this.$pt_alert({
              title: "錯誤",
              content: "廣告載入失敗，請稍後再試"
            })
          })
        }
      }
    },

    // 選擇園區後，要實際生成蛋的步驟
    add_soul_to_place() {
      if (this.bought_item === 'egg') {
        let variety_id = this._get_variety_id()
        this.$emit('add_egg', variety_id, this.selected_place_idx)
        gtag('event', 'get_a_paid_tortoise')
      }
      else {  // free_tortoise
        this.$emit('add_egg', 1, this.selected_place_idx)
        gtag('event', 'get_a_free_tortoise')
      }
    },

    _get_variety_id() {
      // 抽1~100隨機一個數字決定獲得幾星等的龜
      let random_number = Math.floor(Math.random() * 100)
      let matched_star_varieties = Object.values(VARIETIES).filter((i) => {
        if (random_number < this.varieties_rate[1]) {
          return i.stars == 1
        }
        else if (random_number < (this.varieties_rate[1] + this.varieties_rate[2])) {
          return i.stars == 2
        }
        else {
          return i.stars == 3
        }
      })

      // 從該星等的龜們隨機抽1隻給玩家．如果有出錯，回傳預設龜給玩家
      try {
        let idx = Math.floor(Math.random() * matched_star_varieties.length)
        return matched_star_varieties[idx].variety_id
      }
      catch(e) {
        console.error('[ERROR]抽烏龜抽到沒有match的星等，請檢查一下烏龜品種表．已回傳預設龜給玩家')
        return 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.store {
  @include modal_layer;
  @include size;
  @include abs_position();
  overflow: hidden;

  display: flex;

  /** header */
  header {
    .money {
      @include blend_darken_bg;
      display: flex;
      align-items: center;
      position: relative;
      padding: 2px;
      margin: 1px;
      color: rgb(255, 248, 237);
      font-weight: bold;
      text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);
      text-align: center;

      > img {
        width: 24px;
        height: 24px;
        z-index: 0;
      }

      > span {
        padding: 0 $medium 0 $small;
        z-index: 0;
      }
    }
  }

  .main {
    // @include size(100%, calc(100% - 54px));
    width: 100%;
    margin-top: unquote('max(48px, constant(safe-area-inset-top) + 48px)');
    margin-top: unquote('max(48px, env(safe-area-inset-top) + 48px)');
    background-image: url("~@/assets/imgs/background/wood_bg_vertical.jpg");
    background-repeat: repeat-y;
    background-size: 100% auto;

    > div {
      margin: 0 $small;
      height: 100%;
      background-image: url("~@/assets/imgs/background/wood_bg_dark_vertical.jpg");
      background-size: 100% auto;
      overflow-y: auto;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      gap: $medium;
      padding: $medium;
    }

    .store_item_block {
      position: relative;
      height: fit-content;

      //商品吊牌反作用力搖擺動畫 ₍₍٩( ᐛ )۶₎₎♪
      //animation:name duration | timing-function | delay | iteration-count | direction | fill-mode | play-state;
      -webkit-animation: item_swing 0.4s 0s cubic-bezier(.23,.07,.05,1);
      -moz-animation: item_swing 0.4s 0s cubic-bezier(.23,.07,.05,1);
      animation: item_swing 0.4s 0s cubic-bezier(.23,.07,.05,1);

      .item_content {
        cursor: pointer;
        position: absolute;
        bottom: 16%;
        padding: 0 $large; //$large:24px

        & > img {
          width: 80%;
        }
        .item {
          position: relative;

          img {
            width: 80%;
          }
          .star {
            width: 18%;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }

        .price {
          @include blend_darken_bg;
          display: flex;
          position: relative;
          padding: 2px;
          margin-top: $small; //$small:8px
          overflow: hidden;
          border-radius: 50px;
          font-weight: bold;
          color: rgb(255, 248, 237);
          text-align: center;
          text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

          > img {
            width: 28px;
            height: 28px;
            z-index: 0;
          }

          > span {
            @include abs_position($left: 50%);
            transform: translateX(-50%);
            line-height: 30px;
            z-index: 0;
          }
        }

        .free {
          margin-top: $small; //$small:8px
          background-color: $color-gold;
          color: white;
          text-shadow: 1px 1px 1px rgba(77, 51, 18, 0.4);
          border-radius: 50px;
          padding: 5px 0;
          font-weight: bold;
          text-align: center;
        }
      }

      & > img {
        width: 100%;
      }
    }
  }

  .star_container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: $small 0;

    & div:first-child {
      text-align: left;
    }
    & div:last-child {
      text-align: right;
    }
  }
}

</style>

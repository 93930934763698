import GM from '@/components/game_manager'
import audio_manager from "@/components/audio_manager"
import { VARIETIES } from '@/libs/varieties.js'
import { GAME_OBJECT_DEPTH } from '@/settings/depth'

const NORMAL_SCALE = 0.25
const BIG_SCALE = 0.4

export default class BathTortoise {

  constructor(scene, soul, initial_pos) {
    this.scene = scene
    this.physics = scene.physics
    this.soul = soul
    this.status = soul.status

    this.state = null
    this.brush_cnt = 0

    this.init_sprite(initial_pos)
    this.target = null
  }

  get is_in_bathtub() {
    return this === this.scene.tortoise_in_bathtub
  }

  update() {
    if (this.status.is_dead) return

    if (this.status.is_sick) {
      if (this.state === null) {
        this.head_only()
      }
      return
    }

    if (this.state === 'walking') {
      if (this.target) {
        let distance = Phaser.Math.Distance.Between(this.sprite.x, this.sprite.y, this.target.x, this.target.y);

        if (this.sprite.body.speed > 0) {
          //  4 is our distance tolerance, i.e. how close the source can get to the target
          //  The faster it moves, the more tolerance is required.
          if (distance < 4) {
            this.stand()
            setTimeout(() => {
              this.target = null
              this.state = null
            }, 500)
          }
        }
      }
    }
    else if (this.state === 'struggling') {
    }
    else if (this.state === 'ass_shaking') {
    }
    else if (this.state === null) {
      this.walk_to_random_point()
    }
  }

  destroy() {
    /** 有用到event和timer，要在這邊清除 */
    if (this.status) this.status.destroy()
    if (this.sprite) {
      this.sprite.destroy()
      this.sprite.removeAllListeners()
    }
  }

  init_sprite(initial_pos) {
    if (!initial_pos) {
      const game_width = this.scene.game.canvas.width,
        game_height = this.scene.game.canvas.height

      // 預設位置，目前沒用到，但先設置在畫面中間
      initial_pos = {
        x: game_width / 2,
        y: game_height / 2
      }
    }
    this.sprite = this.physics.add.sprite(initial_pos.x, initial_pos.y, `pt_spritesheet_${this.soul.variety_id}`)
      .setScale(NORMAL_SCALE)
      .setDepth(GAME_OBJECT_DEPTH.tortoise)
      .setInteractive({ draggable: true })
      .on('pointerup', this.on_poke.bind(this))
      .on('dragstart', () => {
        GM.set_selected_soul(this.soul)
        this.struggle()
      })
      .on('drag', (pointer, dragX, dragY) => {
        this.sprite.setPosition(dragX, dragY);
      })
      .on('dragend', (pointer) => {
        // 在澡盆外且滑鼠移動距離大於 5，才視為拖曳
        if (pointer.getDistance() <= 5 && !this.is_in_bathtub) {
            this.state = null
            return
        }

        // 只要小龜中心位置在澡盆頂端之下就視為放在澡盆中
        if (this.sprite.getCenter().y > this.scene.bathtub.getBounds().top) {
          // 生病，或 澡盆有其他龜
          if (this.status.is_sick || (this.scene.tortoise_in_bathtub && !this.is_in_bathtub)) {
            // 彈回開始拖曳的位置
            this.sprite.setPosition(this.sprite.input.dragStartX, this.sprite.input.dragStartY)
            this.state = null
          }
          else {
            this.move_to_bathtub()
          }
        }
        // 放在澡盆以外的位置
        else {
          this.state = null
          if (this.is_in_bathtub) {
            this.sprite.setScale(NORMAL_SCALE)
            this.scene.set_tortoise_in_bathtub(null)
          }
        }
      })
  }

  on_poke(pointer) {
    if (this.is_in_bathtub) {
      // 讓小龜在澡盆裡時被戳一下後不要走出澡盆
      return
    }
    // 滑鼠移動距離大於 5，視為拖曳，這邊就不執行點擊動作
    // 且在泡澡頁只有生病才會有點擊動作
    if (pointer.getDistance() > 5) return

    GM.set_selected_soul(this.soul)

    if (!this.status.is_sick) return

    this.status.play_effect('uncomfortable')

    this.poked = true
    this.target_point = null
    this.hide()
    setTimeout(() => {
      this.state = null
      this.poked = false
    }, 3000)
  }

  // --- actions

  die() {
    this.state = 'dead'
    this.sprite_stop()
    // TODO: 放小龜死亡 frame
  }

  head_only() {
    this.state = 'head_only'

    this.sprite_stop()
    this.sprite.anims.play(`head_only_${this.soul.variety_id}`)
  }

  hide() {
    audio_manager.play_audio('sound_effect', 'hide')

    this.state = 'hiding'

    this.sprite_stop()
    this.sprite.anims.play(`hiding_${this.soul.variety_id}`)
  }

  walk_to(target) {
    this.state = 'walking'

    // 這邊的走路速度跟主畫面一樣：依小龜初速 - ((飽食度/100) * 初始速度 * 係數(0.5))
    let velocity = VARIETIES[this.soul.variety_id].velocity - (((this.soul.status.satiety / 100) * VARIETIES[this.soul.variety_id].velocity) * 0.5)

    this.scene.physics.moveToObject(this.sprite, target, velocity)
    this.target = target

    this.sprite.anims.play(`walking_${this.soul.variety_id}`)

    if (target.x > this.sprite.x) {
      this.sprite.setFlipX(true)
    } else {
      this.sprite.setFlipX(false)
    }
  }

  stand() {
    this.state = 'standing'
    this.sprite_stop()
    this.sprite.anims.play(`standing_${this.soul.variety_id}`)
  }

  struggle() {
    this.state = 'struggling'
    this.sprite_stop()
    // TODO: 小龜掙扎 anim
    // this.sprite.anims.play(`struggling_${this.soul.variety_id}`)
  }

  shake_ass() {
    this.state = 'ass_shaking'
    this.sprite_stop()
    this.sprite.anims.play(`ass_shaking_${this.soul.variety_id}`)
  }
  // ---

  be_brushed() {
    if (!this.is_in_bathtub) return

    audio_manager.play_audio('sound_effect', 'brush')

    this.brush_cnt += 1

    if (this.status.is_clean_enough) {
      // 紀錄烏龜被刷的不開心
      gtag('event', 'angry_brush')

      this.status.add_happiness(-1)
      this.stand()
    }
    else {
      // 紀錄烏龜被刷的開心
      gtag('event', 'happy_brush')

      this.status.add_happiness(1)
      this.status.add_cleanliness(1)

      if (this.brush_cnt >= 4 && !this.sprite.anims.isPlaying) {
        this.shake_ass()
      }
    }
  }

  sprite_stop() {
    if (this.sprite) {
      if (this.sprite.body) this.sprite.body.stop()
      if (this.sprite.anims) this.sprite.anims.stop()
    }
  }

  walk_to_random_point() {
    const target = GM.get_random_pos_by_bounds(this.scene.walking_zone.getBounds())
    this.walk_to(target)
  }

  move_to_bathtub() {
    const bathtub_center = this.scene.bathtub.getCenter()
    this.sprite.setPosition(bathtub_center.x, bathtub_center.y - 20) // 高度減20是因為這樣視覺上才在澡盆中間

    this.stand()
    this.sprite.setScale(BIG_SCALE)
    this.sprite.setFlipX(false)
    this.scene.set_tortoise_in_bathtub(this)
  }
}

<template>
  <div ref="root">
    <Modal
      :visible.sync="confirm_visible"
      :content_text="content"
      :html_content="html_content"
      @confirm="user_click"
      :cancel_btn_required="dialog_type === 'confirm'"
      :content_img="content_img"
      :money_amount="money_amount"
    >
      <template v-slot:header>
        <div>
          <div v-if="html_title" v-html="html_title"></div>
          <div
            v-else
            class="dialog-title"
          >
            <div>
              {{ title }}
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/libs/pt-theme/Modal'

export default {
  name: 'Dialog',

  components: {
    Modal
  },

  // 目前只能改title, content，之後需要改其他的再接
  props: {
    // 標題
    title: {
      type: String,
      default: '標題'
    },
    // html格式的標題
    html_title: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度： html_title > title

    // 內容
    content: {
      type: String,
      default: null
    },
    // html格式的內容
    html_content: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度： html_content > content

    // dialog類型, "confirm" | "alert"
    dialog_type: {
      type: String,
      default: 'confirm'
    },

    // 顯示在內容文字上方的圖片
    content_img: {
      type: String,
      default: null
    },

    // 顯示在內容文字下方的金額
    money_amount: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      confirm_visible: false,
      promise_status: null,
    }
  },

  methods: {
    launch() {
      this.confirm_visible = true
      document.body.appendChild(this.$el)

      let _this = this
      return new Promise((resolve, reject) => {
        _this.promise_status = {resolve, reject}
      })
    },

    user_click(ok) {
      if (ok) {
        if (this.promise_status) {
          this.promise_status.resolve()
        }
      }
      else {
        if (this.promise_status) {
          this.promise_status.reject()
        }
      }
      this.$nextTick(() => {
        document.body.removeChild(this.$el)
        this.$destroy()
      })
    }
  },

  mounted() {
    // 讓操作不會穿透到遊戲層
    const root = this.$refs.root
    for (const eventName of ['mouseup','mousedown', 'touchstart', 'touchmove', 'touchend', 'touchcancel']){
      root.addEventListener(eventName, e => e.stopPropagation());
    }
  },
}
</script>

import GM from '@/components/game_manager'
import audio_manager from "@/components/audio_manager"
import { GAME_OBJECT_DEPTH } from '@/settings/depth'

export default class Egg {
  constructor(scene, soul, initial_pos) {
    this.scene = scene
    this.physics = scene.physics
    this.soul = soul

    this.initial_sprite(initial_pos)

    this.poke_cnt = 0
  }

  initial_sprite(initial_pos) {
    if (!initial_pos) {
      let camera = this.scene.cameras.main
      let camera_center_x = camera.centerX + camera.scrollX

      let ground_center_y = this.scene.ground_zone.getBounds().centerY

      initial_pos = {
        x: camera_center_x,
        y: ground_center_y
      }
    }
    this.sprite = this.physics.add.sprite(initial_pos.x, initial_pos.y, 'egg_spritesheet').setScale(0.2)

    this.sprite.setDepth(GAME_OBJECT_DEPTH.egg)  // 避免蓋在烏龜上
    this.sprite.anims.play('egg')
    this.sprite.setInteractive()

    this.sprite.shake = this.scene.plugins.get('rexShake')?.add(this.sprite, {
      duration: 1000,
      magnitude: 5,  // 震動強度
      magnitudeMode: 'decay',  // 震動模式: 'constant' | 'decay'
      // mode: 'effect'  // 看起來沒差... 'effect' | 'behavior'
    })
    this.sprite.on('pointerup', this.on_poke.bind(this))
  }

  update() { }

  destroy() {
    if (this.sprite) this.sprite.removeAllListeners()
    if (this.sprite.body) {
      this.sprite.destroy()
    }
  }

  on_poke() {
    GM.set_selected_soul(this.soul)

    if (this.poke_cnt < 5) {
      audio_manager.play_audio('sound_effect', 'poke_egg')
      this.sprite.shake.shake()

      this.poke_cnt += 1
      if (this.poke_cnt === 5) {
        this.broke()
      }
    }
  }

  async broke() {
    const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay))

    audio_manager.play_audio('sound_effect', 'egg_broken')

    this.sprite.anims.play('egg_broken')
    this.sprite.shake.setEnable(false)
    await wait(1000)

    this.soul.give_birth_to_tortoise()
  }
}

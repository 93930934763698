import Vue from 'vue'
import { FeedbackRepository } from '@/repositories/Feedback'

const FeedbackVState = new Vue({
  data() {
    return {
      feedback: ''
    }
  },

  methods: {
    clear() {
      this.feedback = ''
    },

    save_feedback() {
      FeedbackRepository.save({
        'feedback': this.feedback
      })
    },
  },

  created() {
  }
})


export {
  FeedbackVState
}
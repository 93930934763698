import { VARIETIES } from "@/libs/varieties"

class CollectionManager {
  constructor() {
    this.init_collection_data()
  }

  get reward_exists() {
    for (let collection of this.collection_list) {
      if (collection.status === 'unlocked_receivable') {
        return true
      }
    }
    return false
  }

  get collection_list() {
    let _list = []
    for (let variety_id in VARIETIES) {
      //  處理 status
      let status = ''
      let owned = Boolean(this.data[variety_id]?.birth_cnt)
      let received = this.data[variety_id]?.unlock_reward_received
      if (!owned) {
        status = 'locked'
      }
      else if (!received) {
        status = 'unlocked_receivable'
      }
      else {
        status = 'unlocked_received'
      }

      _list.push({
        ...VARIETIES[variety_id],
        ...this.data[variety_id],
        status: status
      })
    }
    return _list
  }

  init_collection_data() {
    let record = JSON.parse(localStorage.getItem("collection"))
    if (!record) record = {}

    for (let variety_id in VARIETIES) {
      record[variety_id] = record[variety_id] || {
        fail_cnt: 0,
        birth_cnt: 0,
        unlock_reward_received: false
      }
    }

    this.data = record
    this.save()
  }

  mutate(variety_id, key, val) {
    this.data[variety_id][key] = val
    this.save()
  }

  add_cnt(variety_id, key, val) {
    this.data[variety_id][key] += val
    this.save()
  }

  add_birth(variety_id) {
    this.add_cnt(variety_id, 'birth_cnt', 1)
    this.save()
  }

  save() {
    localStorage.setItem('collection', JSON.stringify(this.data))
  }
}

const collection_manager = new CollectionManager()

export default collection_manager

import GM from '@/components/game_manager'
import mission_manager from '@/components/mission_manager'
import { MISSION_CHOICES } from "@/libs/missions"


const TIME_INTERVAL_OF_ROUTINE_DECREASE = 30 * 60 * 1000 // millisecond 飽食度每30分鐘 -1
const TIME_INTERVAL_OF_DIRTY_DECREASE = 10 * 60 * 1000 // millisecond
const STATUS_FIELDS = ['happiness', 'satiety', 'cleanliness', 'health', 'time_of_birth', 'time_of_last_dirty_decrease', 'time_of_last_routine_decrease']
const DEFAULT_STATUS = {
  happiness: 30,
  satiety: 50,
  cleanliness: 50
}

/**
 * routine_decrease: 開心度、飽足感和乾淨度定時減1
 * dirty_decrease: 若乾淨度為最小值，開心度開始定時減1
 */

export default class Status {
  /** static */
  get MAX_OF_STATUS() {
    return 100
  }
  get MIN_OF_STATUS() {
    return 0
  }
  /** */

  get is_full() {
    return this.satiety === this.MAX_OF_STATUS
  }
  get is_starving() {
    return this.satiety === this.MIN_OF_STATUS
  }
  get is_dirty() {
    return this.cleanliness === this.MIN_OF_STATUS
  }
  get is_clean_enough() {
    return this.cleanliness === this.MAX_OF_STATUS
  }
  get is_sick() {
    return this.health !== null && this.health < 60
  }

  // 是否已孵化
  get has_birth() {
    return Boolean(this.time_of_birth)
  }

  //意外死亡
  get is_dead() {
    return this.health <= this.MIN_OF_STATUS
  }

  // 存活天數
  get survival_days() {
    let current = new Date()
    return this.has_birth ? current.diffDays(this.time_of_birth) : 0
  }

  // 身體狀況分數：用於判斷健康度的增減
  get body_score() {
    return this.happiness * 0.1 + this.satiety * 0.45 + this.cleanliness * 0.45
  }

  get default_health() {
    // 健康度預設數值在 65~70 之間
    return 65 + Math.floor(Math.random() * 6)
  }

  constructor(soul, record) {
    this.soul = soul

    this.happiness = null  // 開心度
    this.satiety = null  // 飽食度
    this.cleanliness = null // 乾淨度
    this.health = null // 健康度
    this.time_of_birth = null  // 出生(破蛋)時間
    this.time_of_last_routine_decrease = null  // 最後一次狀態減少的時間
    this.time_of_last_dirty_decrease = null // 最後一次當乾淨度為最小值，開心度減少的時間

    // 已出生多久，單位毫秒
    this.life = null

    this.init_status(record)
  }

  // 由世界時間(在GM裡)每秒呼叫一次
  update() {
    if (this.soul.body) { //等到soul出現才開始對update 做確認
      if (this.is_dead || !this.has_birth) return
      this.check_decrease()  // 確認現在時間有沒有要扣狀態
      this.calculate_life()  // 計算現在的生命

      if (this.is_dead) {
        this.soul.body.die()
        GM.$emit("dead", this.soul)
      }
    }
  }

  destroy() {
    /** 有用到event和timer，要在這邊清除 */
  }

  init_status(record) {
    if (!record) record = {}

    // 將 record 有的值取出
    for (let key of STATUS_FIELDS) {
      if (record[key] != null) {
        if (key.startsWith('time_of')) {
          this[key] = new Date(record[key])
        }
        else {
          this[key] = record[key]
        }
      }
    }

    // 為舊玩家（缺少心情、飽食、清潔度的玩家）的已出生龜補齊數值，目前（2021/5/3）的版本不會用到
    for (let [key, val] of Object.entries(DEFAULT_STATUS)) {
      // 使用雙等號來同時判斷 null 和 undefined
      if (this.time_of_birth && this[key] == null) {
        this[key] = val
      }
    }
    // 為舊玩家補齊健康度數值（隨機值，所以不會放在 DEFAULT_STATUS）
    if (this.time_of_birth && record['health'] == null) {
      this.health = this.default_health
    }

    // 在初始時確認已出生小龜生命是否已結束
    if (this.has_birth) {
      if (this.is_dead) {
        GM.$emit("dead", this.soul)
      }
      this.check_decrease(true)
    }
  }

  be_born() {
    // 初始化其心情、飽食、清潔度
    for (let [key, val] of Object.entries(DEFAULT_STATUS)) {
      this[key] = val
    }
    this.health = this.default_health
    this.time_of_birth = new Date()
    this.time_of_last_routine_decrease = new Date()

    GM.save_tortoises()
  }

  calculate_life() {
    this.life = this.has_birth ? new Date() - this.time_of_birth : 0
  }

  /**
   * 去確認現在時間距離上一次 decrease 的時間差有沒有超過 TIME_INTERVAL，若有則調整狀態
   * 目前每種decrease之間互不影響，例：同時有routine_decrease和dirty_decrease的情況下，每十分鐘開心度總共會扣1, 1, 2
   * @param {*} is_init : true 代表是在初始化跑的 check，若為 false 代表是遊戲當下跑的 check，目前是小龜大便功能會用到
   */
  check_decrease(is_init=false) {
    this.check_routine_decrease(is_init)
    this.check_dirty_decrease()
  }

  check_routine_decrease(is_init) {
    let last_decrease_time = this.time_of_last_routine_decrease.getTime()
    let now_time = new Date().getTime()

    // 每半小時一次的迴圈
    while(last_decrease_time <= now_time - TIME_INTERVAL_OF_ROUTINE_DECREASE) {
      this.add_happiness(-1, false)
      this.add_satiety(-1, false)
      this.add_cleanliness(-1, false)

      // health
      if (this.body_score >= 70) {
        this.add_health(1)
      }
      else if (this.body_score <= 0) {
        this.add_health(-0.15)
      }

      // poop
      if (Math.random() < 1/10) {
        if (!this.is_starving) {
          this.soul.poop(is_init)
          this.add_satiety(-2)
        }
      }

      last_decrease_time += TIME_INTERVAL_OF_ROUTINE_DECREASE
    }
    if (last_decrease_time !== this.time_of_last_routine_decrease.getTime()) {
      this.time_of_last_routine_decrease = new Date(last_decrease_time)
      GM.save_tortoises()
    }
  }

  check_dirty_decrease() {
    if (this.is_dirty) {
      if (this.time_of_last_dirty_decrease) {
        let time_diff = new Date - this.time_of_last_dirty_decrease
        if (time_diff >= TIME_INTERVAL_OF_DIRTY_DECREASE) {
          let amount_of_decrease = Math.floor(time_diff / TIME_INTERVAL_OF_DIRTY_DECREASE)
          this.add_happiness(-amount_of_decrease)

          let exceeded_time = time_diff % TIME_INTERVAL_OF_DIRTY_DECREASE  // 超出的、要繼續累積的時間
          this.time_of_last_dirty_decrease = new Date(new Date() - exceeded_time)
          GM.save_tortoises()
        }
      }
      else {
        this.time_of_last_dirty_decrease = new Date()
        GM.save_tortoises()
      }
    }
    else {
      if (this.time_of_last_dirty_decrease) {
        this.time_of_last_dirty_decrease = null
        GM.save_tortoises()
      }
    }
  }

  // 組成要存進 localStorage 的 status 資料
  get_status_record() {
    let data = {}
    for (let key of STATUS_FIELDS) {
      if (key.startsWith('time_of')) {
        // isostrz 預設只存到分鐘，這邊傳入加上秒的 ISOFormat 格式
        data[key] = this[key]? this[key].isostrz("%Y-%m-%dT%H:%M:%S") : null
      }
      else {
        data[key] = this[key]
      }
    }
    return data
  }

  valid_status_value(val) {
    if (val > this.MAX_OF_STATUS) {
      return this.MAX_OF_STATUS
    }
    else if (val < this.MIN_OF_STATUS) {
      return this.MIN_OF_STATUS
    }
    return val
  }

  add_happiness(value, effect_required=true) {
    this.happiness = this.valid_status_value(this.happiness + value)

    if (effect_required) {
      if (value > 0) {
        this.play_effect('happiness_plus')
      }
      else {
        this.play_effect('happiness_minus')
      }
    }

    GM.save_tortoises()

    if (value > 0 && this.happiness >= 100) {
      mission_manager.make_progress(MISSION_CHOICES.REACH_100_HAPPINESS)
    }
  }

  add_health(value) {
    this.health = this.valid_status_value(this.health + value)
    GM.save_tortoises()
  }

  set_health(value) {
    this.health = value
    GM.save_tortoises()
  }

  add_satiety(value) {
    this.satiety = this.valid_status_value(this.satiety + value)

    if (value > 0 && this.satiety >= 100) {
      // 餵食烏龜的任務
      mission_manager.make_progress(MISSION_CHOICES.REACH_100_SATIETY)
    }

    GM.save_tortoises()
  }

  // 洗澡時需要，直接設為特定數值
  set_satiety(value) {
    this.satiety = value
    GM.save_tortoises()
  }

  add_cleanliness(value) {
    this.cleanliness = this.valid_status_value(this.cleanliness + value)

    if (value > 0 && this.cleanliness >= 100) {
      // 清潔烏龜的任務
      mission_manager.make_progress(MISSION_CHOICES.REACH_100_CLEANLINESS)
    }
    GM.save_tortoises()
  }

  play_effect(image_key) {
    if (!this.soul.body) return

    // offset: 距離soul中心的偏移
    let offset_x = this.soul.body.sprite.flipX ? 40 : -40
    let offset_y = -20
    let effect_position = {
      x: this.soul.body.sprite.x + offset_x,
      y: this.soul.body.sprite.y + offset_y
    }

    if (this.soul.body.scene) {
      let effect_image = this.soul.body.scene.add.image(effect_position.x, effect_position.y, image_key)
      effect_image.setScale(0.1)
      this.soul.body.scene.tweens.add({
          targets: effect_image,
          y: effect_position.y - 50,
          alpha: { from: 1, to: 0},  // 變透明
          duration: 1000,
          ease: 'easeInOut',
          loop: 0,
          completeDelay: 500,
          onComplete: () => {
            effect_image.destroy()
          }
      })
    }
  }
}

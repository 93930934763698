import audio_manager from "@/components/audio_manager"

import { Plugins } from '@capacitor/core';
import { AdSize, AdPosition } from '@capacitor-community/admob';
const { AdMob, Device } = Plugins;

// Document: https://github.com/capacitor-community/admob/tree/v1.3.1

// 插頁式廣告
export class InterstitialAd {
  constructor() {
    this.options = null
    this.is_mobile = null
    this.is_ready = false

    Device.getInfo()
      .then((device_info) => {
        let platform = device_info.platform
        this.is_mobile = platform === 'android' || platform === 'ios'

        if (!this.is_mobile) {
          this.is_ready = true
        }

        if (this.is_mobile) {
          AdMob.initialize({requestTrackingAuthorization: true})

          const ad_id = platform === 'android'? process.env.VUE_APP_ANDROID_AD_ID : process.env.VUE_APP_IOS_AD_ID
          this.options = {
            adId: ad_id,
          }

          this.prepare_ad()
        }
      })

    // 若2秒後還沒載好就直接視為載好
    setTimeout(() => {
      if (!this.is_ready) {
        this.is_ready = true
      }
    }, 2000)
  }

  prepare_ad() {
    console.debug("Try to prepare Interstitial ad")
    AdMob.prepareInterstitial(this.options)
      .then(() => {
        console.debug("Interstitial ad is ready")
        this.is_ready = true
      })
      .catch((e) => {
        console.warning("Failed to prepare Interstitial ad!!", e)
        this.is_ready = true
      })
  }

  show_ad() {
    const try_to_show = () => {
      if (this.is_ready) {
        if (this.is_mobile) {
          console.debug("Show interstitial ad")
          AdMob.showInterstitial()
            .then(() => {
              console.debug("Try to prepare next Interstitial ad")
              this.is_ready = false
              this.prepare_ad()
            })
        }
      }
      else {
        setTimeout(try_to_show, 1000)
      }
    }
    try_to_show()
  }
}

// 橫幅廣告
// 若要使用，須設定 VUE_APP_BANNER_AD_ID，可能還要處理遊戲畫面高度
export class BannerAd {
  constructor() {
    this.options = null
    this.is_mobile = null
    this.is_ready = false

    Device.getInfo()
      .then((device_info) => {
        let platform = device_info.platform
        this.is_mobile = platform === 'android' || platform === 'ios'

        if (this.is_mobile) {
          AdMob.initialize()

          const ad_id = process.env.VUE_APP_BANNER_AD_ID

          this.options = {
            adId: ad_id,
            adSize: AdSize.SMART_BANNER,
            position: AdPosition.BOTTOM_CENTER,
            margin: 0,
          }

          this.is_ready = true
        }
      })

    // 若2秒後還沒載好就直接視為載好
    setTimeout(() => {
      if (!this.is_ready) {
        this.is_ready = true
      }
    }, 2000)
  }

  show_ad() {
    const try_to_show = () => {
      if (this.is_ready) {
        if (this.is_mobile) {
          AdMob.showBanner(this.options)
        }
      }
      else {
        setTimeout(try_to_show, 1000)
      }
    }
    try_to_show()
  }
}

// 獎勵式廣告
class RewardAdManager {
  constructor() {
    this.options = null
    this.is_mobile = null

    Device.getInfo()
    .then((device_info) => {
      let platform = device_info.platform
      this.is_mobile = platform === 'android' || platform === 'ios'

      if (this.is_mobile) {
        AdMob.initialize({requestTrackingAuthorization: true})

        const ad_id = platform === 'android'? process.env.VUE_APP_ANDROID_AD_ID : process.env.VUE_APP_IOS_AD_ID
        this.options = {
          adId: ad_id
        }
        this.prepare_ad()

        AdMob.addListener('onRewarded', () => {
          if (this.rewarded_callback) this.rewarded_callback()
        })
        AdMob.addListener('onRewardedVideoAdClosed', () => {
          console.debug("reward ad closed");
          if (this.closed_callback) this.closed_callback()
          // 廣告關閉時，音效需解除靜音
          audio_manager.unmute()
          this.prepare_ad()
        })
        AdMob.addListener('onRewardedVideoAdFailedToLoad', (error) => {
          console.debug("reward ad failed to load, error", error);
        })
      }
    })
  }

  prepare_ad() {
    AdMob.prepareRewardVideoAd(this.options).catch((error) => {
      console.debug("prepareRewardVideoAd error", error);
    })
  }

  async show_ad(rewarded_callback, failed_callback, closed_callback) {
    if (!this.is_mobile) {
      if (rewarded_callback) rewarded_callback()
      if (closed_callback) closed_callback()
      return
    }

    // Irene@2021-09-02
    // rewarded_callback 會在玩家觀看廣告時長超過獎勵標準時觸發
    // closed_callback 會在玩家關閉廣告時觸發
    // Admod 應有 dismissed 事件，會在玩家提前關閉廣告時觸發，但 capacitor-community/admob@1.3.1 貌似還沒有新增此事件
    this.rewarded_callback = rewarded_callback
    this.closed_callback = closed_callback

    await AdMob.showRewardVideoAd()
    .then(() => {
      // 撥放廣告時，音效需靜音
      audio_manager.mute()
    })
    .catch(() => {
      failed_callback()
      this.prepare_ad()
    })
  }
}
export const reward_ad_manager = new RewardAdManager()


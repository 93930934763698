// 因 Phaser 原生的音效播放在 ios app 上會有延遲的問題，因此這邊使用 howler 來代替
import { Howl } from 'howler'
import { Plugins } from '@capacitor/core'
const { App } = Plugins

const DEFAULT_CONFIG = {
  background_music: 0.5,
  sound_effect: 0.5
}

class AudioManager {
  constructor() {
    this.init_configuration()

    this.audios = {
      background_music: {
        main: new Howl({
          src: [require('@/assets/audio/music.mp3')],
          loop: true
        }
      )},
      sound_effect: {
        click_btn: new Howl({
          src: [require('@/assets/audio/click_btn.mp3')]
        }),
        money: new Howl({
          src: [require('@/assets/audio/pay.mp3')]
        }),
        brush: new Howl({
          src: [require('@/assets/audio/brush.mp3')]
        }),
        poke: new Howl({
          src: [require('@/assets/audio/poke.mp3')]
        }),
        poke_egg: new Howl({
          src: [require('@/assets/audio/poke_egg.mp3')]
        }),
        egg_broken: new Howl({
          src: [require('@/assets/audio/egg_broken.mp3')]
        }),
        eat: new Howl({
          src: [require('@/assets/audio/eat.mp3')]
        }),
        kick: new Howl({
          src: [require('@/assets/audio/kick.mp3')]
        }),
        hide: new Howl({
          src: [require('@/assets/audio/hide.mp3')]
        }),
      }
    }

    this.tune_audio_volume()
    this.play_audio('background_music', 'main')

    // 這段是 為了 Android 可以在離開 app 時靜音
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.unmute()
      }
      else {
        this.mute()
      }
    });
  }

  init_configuration() {
    let record = JSON.parse(localStorage.getItem('audio_config'))
    if (!record) record = {}

    // 賦預設值
    for (let [key, default_value] of Object.entries(DEFAULT_CONFIG)) {
      // 使用雙等號來同時判斷 null 和 undefined
      if (record[key] == null) {
        record[key] = default_value
      }
    }

    this.configuration = record
    this.save()
  }

  /**
   * 調整實際的音效音量，不改變紀錄的值
   * @param audio_group_key: undefined | 'background_music' | 'sound_effect', undefined means to tune all
   * @param volume: undefined | 0~1: undefined means to set to the value of this.configuration
   */
  tune_audio_volume(audio_group_key, volume) {
    for (let _audio_group_key in this.audios) {
      if (!audio_group_key || audio_group_key === _audio_group_key) {
        for (let audio_key in this.audios[_audio_group_key]) {
          let _volume = volume != undefined? volume : this.configuration[_audio_group_key]
          this.audios[_audio_group_key][audio_key].volume(_volume)
        }
      }
    }
  }

  /**
   * 設定紀錄的音量值，並實際調整
   * @param {*} audio_group_key: 'background_music' | 'sound_effect'
   * @param {*} volume: 0~1
   */
  set_volume(audio_group_key, volume) {
    this.configuration[audio_group_key] = volume
    this.save()
    this.tune_audio_volume(audio_group_key)
  }

  play_audio(audio_group_key, audio_key) {
    this.audios[audio_group_key][audio_key].play()
  }

  mute() {
    this.tune_audio_volume(null, 0)
  }

  unmute() {
    this.tune_audio_volume()
  }

  save() {
    localStorage.setItem('audio_config', JSON.stringify(this.configuration))
  }
}

const audio_manager = new AudioManager()

export default audio_manager

import Vue from 'vue'
import Phaser from 'phaser'
import TortoiseSoul from '@/components/tortoise_soul.js'
import place_manager from '@/components/place_manager'


const GameManager = new Vue({
  data() {
    return {
      // 所有場上的生命
      souls: [],

      // 被選中、顯示在狀態列的生命
      selected_soul: null,

      current_scene_key: 'PlayScene',
      scenes: {},
      world_timer: null,
      place_manager: place_manager
    }
  },

  computed: {
    // 場上是否有已孵化的龜
    does_tortoise_exist() {
      for (let soul of this.souls) {
        if (soul.state === 'tortoise') {
          return true
        }
      }
      return false
    },

    current_scene() {
      return this.scenes[this.current_scene_key]
    },

    current_place_idx() {
      return place_manager.current_place_idx
    }
  },

  watch: {
    selected_soul(new_soul, old_soul) {
      gtag('event', 'select_tortoise')
      if (old_soul) old_soul.unhighlight()
      if (new_soul) new_soul.highlight()
    },

    // 切換飼育園區，觸發場景重新生成園區相關物件
    current_place_idx() {
      this.current_scene.recreate_place_objects()
    }
  },

  methods: {
    init(game) {
      this.game = game
      this.init_souls()
      this.connect_scenes()
      this.init_world_timer()
    },

    init_souls() {
      // clear first
      if (this.souls.length) {
        for (let soul of this.souls) {
          soul.destroy()
        }
        this.souls = []
      }

      let stored_tortoises = JSON.parse(localStorage.getItem('tortoises'))
      if (stored_tortoises && stored_tortoises instanceof Array) {
        for (let tortoise_record of stored_tortoises) {
          this.add_soul(new TortoiseSoul(tortoise_record))
        }
      }
      // 若玩家一隻烏龜都沒有且是新玩家的話，要增加一個空白靈魂（基礎龜蛋）
      if (!this.souls.length) {
        let is_new_player = !localStorage.getItem('last_login')
        if (is_new_player) {
          this.add_soul(new TortoiseSoul())
        }
      }
    },

    // 世界時間，每秒去觸發一次需要確認的事情
    init_world_timer() {
      for (let soul of this.souls) {
        soul.status.update()
      }

      if (this.world_timer) clearInterval(this.world_timer)
      this.world_timer = setInterval(() => {
        for (let soul of this.souls) {
          soul.status.update()
        }
      }, 1000)
    },

    // 在 scene.create 時被呼叫
    set_current_scene_key(scene_key) {
      this.current_scene_key = scene_key
    },

    set_selected_soul(soul) {
      this.selected_soul = soul
    },

    connect_scenes() {
      // 因 phaser 生成 scene 沒有事件可以監聽，所以這邊每秒要一次直到 scenes 生成
      const _connect_tortoise = () => {
        if (this.game && this.game.scene.scenes && this.game.scene.scenes[1] && this.game.scene.scenes[2]) {
          this.scenes = {
            'PlayScene': this.game.scene.scenes[1],
            'BathScene': this.game.scene.scenes[2]
          }
        }
        else {
          setTimeout(_connect_tortoise, 1000)
        }
      }
      _connect_tortoise()
    },

    // 每次要在畫面增加生命都應該呼叫這支
    add_soul(soul) {
      this.souls.push(soul)
      this.save_tortoises()
    },

    // 由畫面上的按鈕（vue的）觸發
    switch_scene(scene_key) {
      // scene被stop時會觸發scene的shutdown event, 再被start後，會是一個新的scene物件
      if (this.current_scene_key) this.game.scene.stop(this.current_scene_key)
      if (this.current_scene_key) this.game.scene.start(scene_key)

      // ga tag: bath
      if (scene_key === 'BathScene') {
        gtag('event', 'bath_scene')
      }
    },

    add_an_egg(variety_id, place_idx) {
      // 使用雙等號來同時判斷 null 和 undefined
      if (variety_id == null) {
        variety_id = 1
      }
      if (place_idx == null) {
        place_idx = 0
      }
      let egg_soul = new TortoiseSoul({
        variety_id,
        place_idx
      })
      this.add_soul(egg_soul)
      if (place_idx === place_manager.current_place_idx) {
        egg_soul.create(this.current_scene)
      }
    },

    // 將所有 souls 的狀態存回 localStorage
    save_tortoises() {
      let data = this.souls.map(soul => soul.get_record())
      localStorage.setItem('tortoises', JSON.stringify(data))
    },

    eliminate_soul(soul) {
      let soul_index = this.souls.findIndex(s => s == soul)
      if (soul_index >= 0) {
        this.souls.splice(soul_index, 1)
      }

      this.save_tortoises()
    },

    // utility function
    is_overlaping(sprite_a, sprite_b) {
      return Phaser.Geom.Intersects.RectangleToRectangle(sprite_a.getBounds(), sprite_b.getBounds())
    },

    /**
     * utility function, to get random position in given bounds
     *
     * @param {Object} bounds result of sprite.getBounds()
     * @returns position: {x, y}
     */
    get_random_pos_by_bounds(bounds) {
      const RandomDataGenerator = new Phaser.Math.RandomDataGenerator()
      return {
        x: RandomDataGenerator.integerInRange(bounds.left, bounds.right),
        y: RandomDataGenerator.integerInRange(bounds.top, bounds.bottom)
      }
    }
  }
})

export default GameManager

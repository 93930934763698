const VARIETIES = {
  1: {
    variety_id: 1,
    img: require('@/assets/imgs/tortoise/PT_stand_up_normal.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_normal.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '台灣',
    breed: '橘龜',
  },
  2: {
    variety_id: 2,
    img: require('@/assets/imgs/tortoise/PT_stand_up_red.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_red.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '澳洲',
    breed: '紅龜',
  },
  3: {
    variety_id: 3,
    img: require('@/assets/imgs/tortoise/PT_stand_up_blue.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_blue.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '馬來西亞',
    breed: '藍龜',
  },
  4: {
    variety_id: 4,
    img: require('@/assets/imgs/tortoise/PT_stand_up_green.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_green.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '甘比亞',
    breed: '綠龜',
  },
  5: {
    variety_id: 5,
    img: require('@/assets/imgs/tortoise/PT_stand_up_lightblue_white-stripe.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_lightblue_white-stripe.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '美索不達米亞',
    breed: '藍彩蛋龜',
  },
  6: {
    variety_id: 6,
    img: require('@/assets/imgs/tortoise/PT_stand_up_black_yellow.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_black_yellow.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '法國',
    breed: '黑帽龜',
  },
  7: {
    variety_id: 7,
    img: require('@/assets/imgs/tortoise/PT_stand_up_sulcata.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_sulcata.png'),
    stars: 3,
    unlock_reward: 250,
    velocity: 50,
    distribution: '非洲',
    breed: '蘇卡達象龜',
  },
  8: {
    variety_id: 8,
    img: require('@/assets/imgs/tortoise/PT_stand_up_yellow.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_yellow.png'),
    stars: 1,
    unlock_reward: 150,
    velocity: 50,
    distribution: '梵蒂岡',
    breed: '黃龜',
  },
  9: {
    variety_id: 9,
    img: require('@/assets/imgs/tortoise/PT_stand_up_pink.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_pink.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '開曼群島',
    breed: '粉龜',
  },
  10: {
    variety_id: 10,
    img: require('@/assets/imgs/tortoise/PT_stand_up_purple.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_purple.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '芬蘭',
    breed: '紫龜',
  },
  11: {
    variety_id: 11,
    img: require('@/assets/imgs/tortoise/PT_stand_up_tiffiny.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_tiffiny.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '美國',
    breed: '蒂芬尼龜',
  },
  12: {
    variety_id: 12,
    img: require('@/assets/imgs/tortoise/PT_stand_up_indigo.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_indigo.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '俄國斯',
    breed: '靛藍龜',
  },
  13: {
    variety_id: 13,
    img: require('@/assets/imgs/tortoise/PT_stand_up_night.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_night.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '象牙海岸',
    breed: '夜空藍龜',
  },
  14: {
    variety_id: 14,
    img: require('@/assets/imgs/tortoise/PT_stand_up_plant.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_plant.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '波札那',
    breed: '葉綠龜',
  },
  15: {
    variety_id: 15,
    img: require('@/assets/imgs/tortoise/PT_stand_up_smoke-pink.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_smoke-pink.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '韓國',
    breed: '煙燻粉龜',
  },
  16: {
    variety_id: 16,
    img: require('@/assets/imgs/tortoise/PT_stand_up_blue-yellow.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_blue-yellow.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '法國',
    breed: '藍帽龜',
  },
  17: {
    variety_id: 17,
    img: require('@/assets/imgs/tortoise/PT_stand_up_brown-strip.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_brown-strip.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '美索不達米亞',
    breed: '褐彩蛋龜',
  },
  18: {
    variety_id: 18,
    img: require('@/assets/imgs/tortoise/PT_stand_up_camouflage-grayish-blue.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_camouflage-grayish-blue.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '美國',
    breed: '迷彩藍龜',
  },
  19: {
    variety_id: 19,
    img: require('@/assets/imgs/tortoise/PT_stand_up_camouflage-green.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_camouflage-green.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '美國',
    breed: '迷彩綠龜',
  },
  20: {
    variety_id: 20,
    img: require('@/assets/imgs/tortoise/PT_stand_up_watermelon-y.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_watermelon-y.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '台灣',
    breed: '黃西龜',
  },
  21: {
    variety_id: 21,
    img: require('@/assets/imgs/tortoise/PT_stand_up_watermelon-r.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_watermelon-r.png'),
    stars: 2,
    unlock_reward: 150,
    velocity: 50,
    distribution: '台灣',
    breed: '紅西龜',
  },
  22: {
    variety_id: 22,
    img: require('@/assets/imgs/tortoise/PT_stand_up_Hermann.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_Hermann.png'),
    stars: 3,
    unlock_reward: 250,
    velocity: 50,
    distribution: '東歐, 南歐, 巴爾幹半島, 土耳其',
    breed: '赫曼陸龜',
  },
  23: {
    variety_id: 23,
    img: require('@/assets/imgs/tortoise/PT_stand_up_Leopard.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_Leopard.png'),
    stars: 3,
    unlock_reward: 250,
    velocity: 50,
    distribution: '南非北部',
    breed: '東部豹龜',
  },
  24: {
    variety_id: 24,
    img: require('@/assets/imgs/tortoise/PT_stand_up_Leopard-pardalis.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_Leopard-pardalis.png'),
    stars: 3,
    unlock_reward: 250,
    velocity: 50,
    distribution: '非洲西南部',
    breed: '西部豹龜',
  },
  25: {
    variety_id: 25,
    img: require('@/assets/imgs/tortoise/PT_stand_up_Indian-star.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_Indian-star.png'),
    stars: 3,
    unlock_reward: 250,
    velocity: 50,
    distribution: '印度',
    breed: '印度星龜',
  },
  26: {
    variety_id: 26,
    img: require('@/assets/imgs/tortoise/PT_stand_up_Aldabra.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_Aldabra.png'),
    stars: 3,
    unlock_reward: 250,
    velocity: 50,
    distribution: '亞達伯拉群島',
    breed: '亞達伯拉象龜',
  },
  27: {
    variety_id: 27,
    img: require('@/assets/imgs/tortoise/PT_stand_up_ivory.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_ivory.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '秘魯',
    breed: '米白龜',
  },
  28: {
    variety_id: 28,
    img: require('@/assets/imgs/tortoise/PT_stand_up_gainsboro-gray.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_gainsboro-gray.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '香港',
    breed: '灰白龜',
  },
  29: {
    variety_id: 29,
    img: require('@/assets/imgs/tortoise/PT_stand_up_black.png'),
    spritesheet_img: require('@/assets/imgs/tortoise/PT_spritesheet_black.png'),
    stars: 1,
    unlock_reward: 100,
    velocity: 50,
    distribution: '剛果',
    breed: '黑龜',
  },
}

export {
  VARIETIES
}


<template>
  <div class="place_page" v-show="visible">
    <Header
      :title="'飼育園區'"
      @close="close_place_page"
    >
      <template v-slot:right_content>
        <div class="wallet">
          <img src="@/assets/imgs/UI/coin.png">
          <span>{{ money.amount }}</span>
        </div>
      </template>
    </Header>
    <main class="main">
      <!-- 園區列表 -->
      <div class="place_cards_container" ref="place_cards_container">
        <div
          v-for="(place, idx) in places"
          :key="'place_' + idx"
          :id="'place_' + idx"
          class="place_card"
          :class="{ 'active': idx === selected_place_idx }"
          @click="change_selected_place(idx)"
          v-click-sound
        >
          <img
            class="grass_bg"
            src="@/assets/imgs/background/collection_bg.png"
          >
          <div class="place_name_container">
            <img
              src="@/assets/imgs/UI/place_name.png"
            >
            <div
              class="place_name"
            >
              飼育園{{ idx+1 }}
            </div>
          </div>
        </div>

        <!-- buy new place -->
        <div
          class="place_card locked"
        >
          <img
            class="grass_bg"
            src="@/assets/imgs/background/collection_bg.png"
          >
          <div
            v-if="!places_limit_reached"
            class="lock_mask"
            @click="launch_purchase_confirm"
          >
            <div class="round_bar darken plus_btn">
              <i class="icon-symbol_add"></i>
            </div>
          </div>
          <div
            v-else
            class="lock_mask disabled"
          >
            - Coming soon! -
          </div>
        </div>
      </div>

      <!-- 園區烏龜 -->
      <div class="card--board">
        <div class="title">
          園區烏龜 ({{ selected_place_soul_list.length }}/{{ MAX_NUM_OF_PLACE_SOULS }})
        </div>
        <div class="darken_block">
          <div class="wrap_list" v-if="selected_place_soul_list.length">
            <div
              v-for="soul in selected_place_soul_list"
            >
              <div class="tortoise_box" style="display: flex;">
                <div class="tortoise_pic">
                  <img
                    :src="soul.profile_pic_path"
                    alt="tortoise_pic"
                    :class="soul.state === 'egg'? 'egg_img' : 'tortoise_img'"
                  >
                </div>
                <div class="tortoise_name">
                  {{ soul.name.length? soul.name : '--' }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            目前還沒有烏龜喔
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header'
import place_manager from '@/components/place_manager'
import GM from '@/components/game_manager'

export default {
  name: 'Place',

  components: {
    Header
  },

  props: {
    visible: {
      type: Boolean
    },

    money: {
      type: Object
    }
  },

  data() {
    return {
      place_manager: place_manager,
      selected_place_idx: place_manager.current_place_idx
    }
  },

  computed: {
    places_limit_reached() {
      return place_manager.places_limit_reached
    },

    places() {
      return place_manager.places
    },

    MAX_NUM_OF_PLACE_SOULS() {
      return place_manager.MAX_NUM_OF_PLACE_SOULS
    },

    selected_place_soul_list() {
      return GM.souls.filter(soul => soul.place_idx === this.selected_place_idx)
    }
  },

  watch: {
  },

  methods: {
    change_selected_place(idx) {
      gtag('event', 'change_place')
      this.selected_place_idx = idx
    },

    launch_purchase_confirm() {
      this.$pt_confirm({
        title: '新增飼育區',
        content: '新增加一個飼育區，可以飼養更多的烏龜',
        money_amount: '3000'
      })
        .then(() => {
          if (this.money.pay(3000)) {
            gtag('event', 'add_place')
            place_manager.add_place()
            this.selected_place_idx = this.places.length-1
          }
        })
        .catch(() => {})
    },

    close_place_page() {
      place_manager.set_current_place_idx(this.selected_place_idx)
      this.$emit('update:visible', false)
    }
  },
}
</script>

<style lang="scss" scoped>
.place_page {
  @include modal_layer;
  @include size;
  @include abs_position(0, 0);
  overflow: hidden;
  display: flex;
}

.wallet {
  @include blend_darken_bg;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px;
  margin: 1px;
  color: rgb(255, 248, 237);
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);
  text-align: center;

  > img {
    width: 24px;
    height: 24px;
    z-index: 0;
  }

  > span {
    padding: 0 $medium 0 $small;
    z-index: 0;
  }
}

.main {
  width: 100%;
  margin-top: unquote('max(48px, constant(safe-area-inset-top) + 48px)');
  margin-top: unquote('max(48px, env(safe-area-inset-top) + 48px)');
  background-image: url("~@/assets/imgs/background/paper_bg.jpg");
  background-repeat: repeat-y;
  background-size: 100% auto;
  overflow-y: scroll;
}
.main::-webkit-scrollbar {
  display: none;
}

.place_card {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  border-radius: $ex-small; //$ex-small:4px
  border: 6px solid white;
  overflow: hidden;
  box-shadow: 1px 1px 1px rgba(77, 41, 18, 0.2);
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  img {
    @include size;
  }

  &.active {
    border: 6px solid rgb(233, 156, 68);
  }

  &.locked {
    border: none;
  }
}

.lock_mask {
  @include abs_position(0, 0, 0, 0);
  background-color: rgba($color-black--80, 0.7);
  border: 6px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 248, 237);
  text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

  .plus_btn {
    width: 36px;
    height: 36px;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.place_name_container {
  @include abs_position($bottom: 0, $left: 0);
  @include size(80px, null);
  margin-left: $ex-small;

  > .place_name {
    @include abs_position(12px, $left: 12px);
    color: rgb(255, 248, 237);
    font-weight: bold;
  }
}

.place_cards_container {
  margin: $medium;
  display: flex;
  gap: $medium;
  overflow-x: scroll;
}
.place_cards_container::-webkit-scrollbar {
  display: none;
}

// 覆蓋公版
.card--board {
  margin: $medium;
}

.tortoise_box {
  display: flex;
  align-items: center;
  gap: $small;
}

.tortoise_pic {
  @include tortoise_pic(44px);
}

.tortoise_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrap_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: $medium;
}
</style>

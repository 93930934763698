
<template>
  <header>
    <img src="@/assets/imgs/UI/header_plant.png" class="header_plant">
    <div class="items_container">
      <div
        class="return_btn"
        @click="close"
        v-click-sound
      >
        <i class="icon-arrow_left"></i>
      </div>
      <slot name="right_content"></slot>
    </div>
    <div class="title">{{ title }}</div>
  </header>
</template>

<script>

export default {
  props: {
    title: {
      type: String
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
/* todo: 木頭按鈕文字跟border顏色要統一成下面這個 */
@mixin modal_return_btn {
  @include wood_bg;
  border: 3px solid rgb(203, 176, 140);
  border-radius: $small; //$small:8px
  font-weight: bold;
  color: rgb(255, 248, 237);
  padding: $small $large; //$small:8px $large:24px
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 3px rgba(77, 51, 18, 0.4);
  text-shadow: 1px 1px 1px rgba(77, 51, 18, 0.4);
}

/** header */
header {
  @include wood_bg;
  @include abs_position;
  width: 100%;
  box-shadow: 0px 2px 0px rgb(150, 122, 80);
  z-index: 1;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  .title {
    padding: $small;
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: rgb(255, 248, 237);
    letter-spacing: 2px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(77, 51, 18, 0.4);
  }

  .items_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding: $ex-small $medium; //$medium:16px
    width: 100%;
  }

  > .header_plant {
    @include abs_position(0, $left: 0);
    height: 110%;
    pointer-events: none;
  }
}

.return_btn {
  @include modal_return_btn;
}

</style>

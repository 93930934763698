/* eslint-disable */
import axios from 'axios'
import urljoin from 'url-join'

const feedback_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

feedback_axios.interceptors.response.use(res => {
  return res
})
feedback_axios.defaults.headers.get['Accept'] = 'application/json'
feedback_axios.defaults.headers.post['Content-Type'] = 'application/json'
feedback_axios.defaults.withCredentials = true
feedback_axios.defaults.xsrfCookieName = 'csrftoken'
feedback_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
feedback_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


function response_wrapper(response) {
  return {
    'data': response.data.data,
    'message': response.data.msg,
    'code': response.status
  }
}


const FeedbackRepository = {

  save: function(data) {
    let formData = new FormData()
    formData.append('feedback', data.feedback)

    let _url = 'feedback/save/'
    return new Promise((resolve, reject) => {
      feedback_axios.post(_url, formData)
        .then(res => {
          resolve(response_wrapper(res))
        })
        .catch(err => {
          reject({
            data: err.response.data.data,
            message: err.response.data.message,
            code: err.response.status
          })
        })
    })
  },
}


export {
  FeedbackRepository
}
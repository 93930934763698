<template>
  <div
    v-if="now_loading"
    :class="{'loading_root': true, 'finish_loading_animation': finish_loading_animated}"
  >

    <div :class="root_background"></div>

    <div
      v-show="sunny_visible"
      :class="{'fadeout': sunny_fadeout_animated, 'fadein': sunny_fadein_animated}"
    >
      <div
        class="sunny_background"
      >
      </div>
      <div
        id="sunny_day_loading"
      >
        <img class="tortoise_img" :src="tortoise_walking">
        <img class="sun" :src="sun">
        <img class="sunny_cloud_left" :src="sun_cloud">
        <img class="sunny_cloud_right" :src="sun_cloud">
        <div class="sunny_loading_text">{{ loading_text }}</div>
      </div>
    </div>

    <div
      v-show="cloudy_visible"
      :class="{'fadeout': cloudy_fadeout_animated, 'fadein': cloudy_fadein_animated}"
    >
      <div
        class="cloudy_background"
      >
      </div>
      <div
        id="rainy_day_loading"
      >
        <img class="tortoise_img" :src="tortoise_head_only">
        <div class="weather_box">
          <img :class="{'rainy_cloud_left': true, 'rainy_cloud_left_slide_in': cloudy_fadein_animated}" :src="rainy_cloud_left">
          <img :class="{'rainy_cloud_right': true, 'rainy_cloud_right_slide_in': cloudy_fadein_animated}" :src="rainy_cloud_right">
        </div>
        <div class="rainy_loading_text">{{ loading_text }}</div>
      </div>

    </div>

    <div
      v-show="rainy_visible"
      :class="{'fadeout': rainy_fadeout_animated, 'fadein': rainy_fadein_animated}"
    >
      <div
        class="rainy_background"
      >
      </div>
      <div
        id="rainy_day_loading"
      >
        <img class="tortoise_img" :src="tortoise_hiding">
        <div class="weather_box">
          <div :class="{'loading_rainy_container': true, 'start_rain': rainy_fadein_animated, 'stop_rain': rainy_fadeout_animated}">
            <div class="rain_drop drop1"></div>
            <div class="rain_drop drop2"></div>
            <div class="rain_drop drop3"></div>
            <div class="rain_drop drop4"></div>
            <div class="rain_drop drop5"></div>
            <div class="rain_drop drop6"></div>
            <div class="rain_drop drop7"></div>
            <div class="rain_drop drop8"></div>
            <div class="rain_drop drop9"></div>
            <div class="rain_drop drop10"></div>
          </div>
          <img :class="{'rainy_cloud_left': true, 'rainy_cloud_left_slide_out': rainy_fadeout_animated}" :src="rainy_cloud_left">
          <img :class="{'rainy_cloud_right': true, 'rainy_cloud_right_slide_out': rainy_fadeout_animated}" :src="rainy_cloud_right">
        </div>
        <div class="rainy_loading_text">{{ loading_text }}</div>
      </div>
    </div>

    <!-- <button @click="change_weather_3_stage" style="z-index: 200; position:absolute; top: 0px; right: 0px;">fade out</button> -->

  </div>
</template>

<script>
export default {
  name: 'Loading',

  props: {
    end_loading: {
      type: Boolean
    }
  },

  data() {
    return {
      tortoise_walking: require('@/assets/imgs/tortoise_walking.gif'),
      tortoise_head_only: require('@/assets/imgs/tortoise_head_only.png'),
      tortoise_hiding: require('@/assets/imgs/tortoise_hiding.png'),
      sun: require('@/assets/imgs/loading/loading_img-sun.png'),
      sun_cloud: require('@/assets/imgs/loading/loading_img-sun-cloud.png'),
      rainy_cloud_left: require('@/assets/imgs/loading/loading_img-rain-big_cloud.png'),
      rainy_cloud_right: require('@/assets/imgs/loading/loading_img-rain-small_cloud.png'),

      now_loading: true,

      weather: "sunny",
      loading_text: "Loading...",

      sunny_visible: false,
      cloudy_visible: false,
      rainy_visible: false,

      sunny_fadeout_animated: false,
      sunny_fadein_animated: false,
      cloudy_fadeout_animated: false,
      cloudy_fadein_animated: false,
      rainy_fadeout_animated: false,
      rainy_fadein_animated: false,
      finish_loading_animated: false,
    }
  },

  computed: {

    root_background() {
      if (this.weather === "sunny") {
        return "sunny_background"
      }
      else if (this.weather === "cloudy") {
        return "cloudy_background"
      }
      else {
        return "rainy_background"
      }
    }
  },

  methods: {
    change_weather_2_stage() {
      if (this.weather === "sunny") {
        this.sunny_fadein_animated = false
        this.rainy_fadeout_animated = false
        this.sunny_fadeout_animated = true
        this.rainy_fadein_animated = true
        this.rainy_visible = true
        this.weather = "rainy"
        setTimeout(() => {this.sunny_visible = false}, 1000)
      }
      else {
        this.rainy_fadein_animated = false
        this.sunny_fadeout_animated = false
        this.rainy_fadeout_animated = true
        this.sunny_fadein_animated = true
        this.sunny_visible = true
        this.weather = "sunny"
        setTimeout(() => {this.rainy_visible = false}, 1000)
      }

    },

    change_weather_3_stage() {
      if (this.weather === "sunny") {
        this.sunny_fadein_animated = false
        this.cloudy_fadeout_animated = false
        this.sunny_fadeout_animated = true
        this.cloudy_fadein_animated = true
        this.cloudy_visible = true
        this.weather = "cloudy"
        setTimeout(() => {this.sunny_visible = false}, 1000)
      }
      else if (this.weather === "cloudy") {
        this.cloudy_fadein_animated = false
        this.rainy_fadeout_animated = false
        this.cloudy_fadeout_animated = true
        this.rainy_fadein_animated = true
        this.rainy_visible = true
        this.weather = "rainy"
        setTimeout(() => {this.cloudy_visible = false}, 1000)
      }
      else {
        this.rainy_fadein_animated = false
        this.sunny_fadeout_animated = false
        this.rainy_fadeout_animated = true
        this.sunny_fadein_animated = true
        this.sunny_visible = true
        this.weather = "sunny"
        setTimeout(() => {this.rainy_visible = false}, 1000)
      }

    },

    change_weather_timer() {
      if (this.now_loading) {
        setTimeout(() => {
          this.change_weather_3_stage()
          this.change_weather_timer()
        }, 4000)
      }
    },

    finish_loading() {
      this.finish_loading_animated = true
      setTimeout(() => {
        this.now_loading = false
        this.$emit('finish_loading_animated')
      }, 2000)
    }
  },

  watch: {
    'end_loading': {
      handler(end_loading) {
        if (end_loading) {
          this.finish_loading()
        }
      },
      immediate: true
    }
  },

  created() {
    this.sunny_visible = true
    this.change_weather_timer()
  }
}
</script>

<style lang="scss" scoped>

  .loading_root {
    @include modal_layer(10);  // 最上層，比新手教學還上層
    @include full_size;
  }

  .finish_loading_animation {
    animation: masking_out 1s;
    animation-fill-mode: forwards;
  }

  @keyframes masking_out {
    0% {
      clip-path: circle(1000px);
    }
    100% {
      clip-path: circle(0px);
    }
  }

  #sunny_day_loading{
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);

    .sun {
      position: absolute;
      left: 52px;
      top: 12px;
      width: 48px;
      animation:spin 8s linear infinite;
    }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

    .sunny_cloud_left {
      position: absolute;
      left: 36px;
      top: 49px;
      width: 31px;
    }

    .sunny_cloud_right {
      position: absolute;
      right: 37px;
      top: 27px;
      width: 42px;
    }

    .tortoise_img {
      position: absolute;
      top: 92px;
      left: 50px;
      width: 86px;
    }

    .sunny_loading_text {
      position: absolute;
      font-family:  'Lato';
      letter-spacing: 1px;
      width: 200px;
      left: 0;
      top: 170px;
      font-size: 20px;
      color: #7C7C7C;
    }
  }

  #rainy_day_loading{
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);

    .loading_rainy_container {
      position: absolute;
      left: 60px;
      top: $ex-large; //$ex-large:32px
      width: 80px;
      height: 100px;

      .rain_drop {
        position: absolute;
        width: 1.5px;
        border-radius: 1px;
        height: 8px;
        pointer-events: none;
        animation: loading_rain_drop 0.8s linear infinite;
      }

      @keyframes loading_rain_drop {
        0% {
          transform: translateY(0);
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        100% {
          transform: translateY(100px);
        }
      }

      .drop1 {
        left: 10px;
        top: 10px;
        animation-delay: -0.8s;
        background-color: #777777;
      }

      .drop2 {
        left: 20px;
        top: 0px;
        animation-delay: -0.7s;
        background-color: #FFFFFF;
      }

      .drop3 {
        left: 30px;
        top: 15px;
        animation-delay: -0.2s;
        background-color: #BBBBBB;
      }

      .drop4 {
        left: 40px;
        top: 0px;
        animation-delay: -0.8s;
        background-color: #777777;
      }

      .drop5 {
        left: 50px;
        top: 8px;
        animation-delay: 0.5s;
        background-color: #BBBBBB;
      }

      .drop6 {
        left: 60px;
        top: 0px;
        animation-delay: 0.1s;
        background-color: #FFFFFF;
      }

      .drop7 {
        left: 70px;
        top: 15px;
        animation-delay: 1s;
        background-color: #BBBBBB;
      }

      .drop8 {
        left: 20px;
        top: 0px;
        animation-delay: 0.3s;
        background-color: #BBBBBB;
      }

      .drop9 {
        left: 40px;
        top: 10px;
        animation-delay: 0.7s;
        background-color: #FFFFFF;
      }

      .drop10 {
        left: 10px;
        top: 5px;
        animation-delay: 0.8s;
        background-color: #777777;
      }
    }

    .start_rain {
      animation: start_rain 1s linear;
    }

    @keyframes start_rain {
      0% { opacity: 0; }
      50% { opacity: 0; }
      100% { opacity: 1; }
    }

    .stop_rain {
      animation: stop_rain 1s linear;
      animation-fill-mode: forwards;
    }

    @keyframes stop_rain {
      0% { opacity: 1; }
      50% { opacity: 0; }
      100% { opacity: 0; }
    }

    .rainy_cloud_left {
      position: absolute;
      left: 54px;
      top: 9px;
      width: 67px;
    }

    .rainy_cloud_left_slide_in {
      animation: rainy_cloud_left_slide_in 0.6s ease-in-out;
    }

    .rainy_cloud_left_slide_out {
      animation: rainy_cloud_left_slide_out 0.6s ease-in-out;
      animation-fill-mode: forwards;
    }

    @keyframes rainy_cloud_left_slide_in {
      0% {
        transform: translateX(-80px);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        transform: translateX(0);
      }
    }

    @keyframes rainy_cloud_left_slide_out {
      0% {
        transform: translateX(0);
      }
      50% {
        opacity: 1;
      }
      100% {
        transform: translateX(-80px);
        opacity: 0;
      }
    }

    .rainy_cloud_right {
      position: absolute;
      right: 54px;
      top: 25px;
      width: 50px;
    }

    .rainy_cloud_right_slide_in {
      animation: rainy_cloud_right_slide_in 0.6s ease-in-out;
    }

    .rainy_cloud_right_slide_out {
      animation: rainy_cloud_right_slide_out 0.6s ease-in-out;
      animation-fill-mode: forwards;
    }

    @keyframes rainy_cloud_right_slide_in {
      0% {
        transform: translateX(80px);
        opacity: 0;
      }
      50% {
        opacity: 1
      }
      100% {
        transform: translateX(0);
      }
    }

    @keyframes rainy_cloud_right_slide_out {
      0% {
        transform: translateX(0);
      }
      50% {
        opacity: 1
      }
      100% {
        opacity: 0;
        transform: translateX(80px);
      }
    }

    .tortoise_img {
      position: absolute;
      top: 92px;
      left: 50px;
      width: 86px;
    }

    .rainy_loading_text {
      position: absolute;
      font-family:  'Lato';
      letter-spacing: 1px;
      width: 200px;
      left: 0;
      top: 170px;
      font-size: 20px;
      color: #FFFFFF;
    }
  }

  .sunny_background {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #F8F4E4;
  }

  .cloudy_background {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #B1BCC3;
  }

  .rainy_background {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #31393E;
  }

  .fadeout {
    -webkit-animation:fadeout 1s linear;
    -moz-animation:fadeout 1s linear;
    animation:fadeout 1s linear;
    animation-fill-mode: forwards;         /* When the spec is finished */
  }

  @-moz-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  @-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  @keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  .fadein {
    -webkit-animation:fadein 1s linear;
    -moz-animation:fadein 1s linear;
    animation:fadein 1s linear;
    animation-fill-mode: forwards;         /* When the spec is finished */
  }

  @-moz-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

</style>

import audio_manager from "@/components/audio_manager"
import { pt_alert } from '@/libs/pt-theme'

const DEFAULT_MONEY = 200

export default class Money {
  constructor() {
    if ('money' in localStorage) {
      this.amount = JSON.parse(localStorage.getItem('money'))
    }
    // Irene@2021-06-24 將 localStorage 的 amount 改為 money。下面這段是為了避免舊玩家的錢被清空，過段時間可以刪掉
    else if ('amount' in localStorage) {
      this.amount = JSON.parse(localStorage.getItem('amount'))
      localStorage.setItem('money', this.amount)
      localStorage.removeItem('amount')
    }
    else {
      this.init_amount()
    }
  }

  init_amount() {
    this.amount = DEFAULT_MONEY
  }

  check_balance_enough(amount) {
    if (this.amount - amount < 0) {
      gtag('event', 'money_not_enough')
      pt_alert({
        title: '無法購買',
        content: '烏龜幣不足'
      })
      return false
    }
    return true
  }

  earn(money) {
    localStorage.setItem('money', this.amount + money)
    this.amount = JSON.parse(localStorage.getItem('money'))

    audio_manager.play_audio('sound_effect', 'money')
  }

  pay(money, balance_check_required=true) {
    if (!balance_check_required || this.check_balance_enough(money)) {
      localStorage.setItem('money', (this.amount - money) <= 0 ? 0 : (this.amount - money))
      this.amount = JSON.parse(localStorage.getItem('money'))

      audio_manager.play_audio('sound_effect', 'money')
      return true
    }
    return false
  }
}

/* eslint-disable */
import axios from 'axios'
import urljoin from 'url-join'

const weather_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

weather_axios.interceptors.response.use(res => {
  return res
})
weather_axios.defaults.headers.get['Accept'] = 'application/json'
weather_axios.defaults.headers.post['Content-Type'] = 'application/json'
weather_axios.defaults.withCredentials = true
weather_axios.defaults.xsrfCookieName = 'csrftoken'
weather_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
weather_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


function response_wrapper(response) {
  return {
    'data': response.data.data,
    'message': response.data.msg,
    'code': response.status
  }
}


const WeatherRepository = {

  get_obs: function(data) {

    let params = {
      params: {
        'lat': data.lat,
        'lon': data.lon
      }
    }

    let _url = 'obs_data/'
    return new Promise((resolve, reject) => {
      weather_axios
        .get(_url, params)
        .then(response => {
          if (response.data.data) {
            resolve(response_wrapper(response))
          }
        })
        .catch(error => {
          console.error(error)
          //後端應該要傳回key有data 在做原本的reject(response_wrapper(error))
          reject()
        })
    })
  },
}


export {
  WeatherRepository
}

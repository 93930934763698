<template>
  <div
    v-show="visible"
    class="modal-container"
    :class="{ mask : mask_required }"
    @click.self="mask_closable ? _toggle(false) : null"
  >

    <div class="modal-card card--board" :class="card_style">
      <header class="modal-header title">
        <slot name="header">
          <div>
            {{ title }}
          </div>
        </slot>
      </header>

      <i
        v-show="closable"
        class="icon-symbol_close modal-close_btn"
        @click="_toggle(false)"
        v-click-sound
      >
      </i>

      <main class="darken_block">
        <div class="modal-content">
          <slot name="content">
            <img
              v-if="content_img"
              :src="content_img"
              class="content_img"
            >
            <slot name="content_text">
              <div v-if="html_content" v-html="html_content"></div>
              <div v-else class="content_text">
                {{ content_text }}
              </div>
            </slot>
            <div v-if="money_amount" class="money_block">
              <img src="@/assets/imgs/UI/coin.png">
              <span>{{ money_amount }}</span>
            </div>
          </slot>
        </div>

        <slot name="footer">
          <div
            v-if="footer_visible"
            class="double_btn_group"
          >
            <button
              v-if="cancel_btn_required"
              class="wood_btn"
              @click="_cancel"
              v-click-sound
            >
              {{ cancel_text }}
            </button>
            <slot name="ok_btn">
              <button
                class="wood_btn"
                :disabled="loading || disabled"
                :loading="loading"
                @click="_ok"
                v-click-sound
              >
                <span>
                  {{ ok_text }}
                </span>
                <div></div>
              </button>
            </slot>
          </div>
        </slot>
      </main>

      <slot name="bottom">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    // 顯示/隱藏modal，外層用 :visible.sync 控制
    visible: {
      type: Boolean,
      default: false
    },

    // 標題
    title: {
      type: String,
      default: "標題"
    },

    // 內容
    content_text: {
      type: String,
      default: "內容"
    },
    // html格式的內容
    html_content: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度：slot > html_content > content_text

    // 確認按鈕的文字
    ok_text: {
      type: String,
      default: "確定"
    },

    // 取消按鈕的文字
    cancel_text: {
      type: String,
      default: "取消"
    },

    // 是否需要取消按鈕
    cancel_btn_required: {
      type: Boolean,
      default: false
    },

    // 是否需要footer
    footer_visible: {
      type: Boolean,
      default: true
    },

    // 顯示在內容文字上方的圖片
    content_img: {
      type: String,
      default: null
    },

    // 顯示在內容文字下方的金額
    money_amount: {
      type: String,
      default: null
    },

    // 確認按鈕的狀態：disabled and loading，若有使用到則需由外層手動關閉modal
    // 若不希望點選ok直接關閉，也可以將loading設為false，由外部關閉
    loading: {
      type: Boolean,
      default: null
    },

    // 確認按鈕的狀態：disabled，用在content中有必填欄位的情況
    disabled: {
      type: Boolean,
      default: false
    },

    // 是否有右上角關閉按鈕
    closable: {
      type: Boolean,
      default: false
    },

    // 是否需要遮罩是半透明黑
    mask_required: {
      type: Boolean,
      default: true
    },

    // 是否能點選周圍遮罩關閉
    mask_closable: {
      type: Boolean,
      default: false
    },

    // 是否能按esc關閉
    esc_to_close: {
      type: Boolean,
      default: false
    },

    // 是否能按enter確定
    enter_to_ok: {
      type: Boolean,
      default: false
    },

    // 自訂card的style class
    card_style: {
      type: String,
      default: null
    }
  },

  methods: {
    _cancel() {
      this.$emit('confirm', false)
      this._toggle(false)
    },

    _ok() {
      this.$emit('confirm', true)
      if (this.loading === null) { // 外層沒使用到loading，自動關閉modal
        this._toggle(false)
      }
    },

    _toggle(visible) {
      this.$emit('update:visible', visible)
    }
  },

  created() {
    // esc 關閉
    if (this.esc_to_close) {
      document.addEventListener("keyup", (e) => {
        if (e.key === "Escape" && this.visible) {
          this._toggle(false)
        }
      })
    }
    // enter 確定
    if (this.enter_to_ok) {
      document.addEventListener("keyup", (e) => {
        if (e.key === "Enter" && this.visible) {
          this._ok()
        }
      })
    }
  }
}
</script>

<style lang="scss" scopde>

.modal-container {
  @include modal_layer;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;

  &.mask {
    background-color: rgba($color-black, 0.3);
  }
}

.modal-card {
  @include abs_position(50%);
  transform: translateY(-50%);
  width: 290px;
  margin: auto;
  position: relative;
}

.modal-content {
  text-align: center;
  margin: $small 0;
  overflow: scroll;
  color: rgb(255, 248, 237);
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-close_btn {
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 248, 237);
  position: absolute;
  top: $small; //$small:8px
  right: $small; //$small:8px
  cursor: pointer;
}

.content_img {
  max-height: 80px;
  max-width: 200px;
  margin-bottom: $small; //$small:8px
}

.content_text {
  text-align: center;
  margin: $small 0;
  font-size: 16px;
  letter-spacing: 1px;
}

.money_block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $ex-small 0; //$medium:16px
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(71, 41, 18, 0.5);

  > img {
    width: 24px;
    margin-right: $small; //$small:8px
  }
}

.double_btn_group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    white-space: nowrap;
  }
}

.wood_btn {
  margin: $small; //$small:8px
}
</style>>

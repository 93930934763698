/*
這裡定義遊戲物件的Depth
數值越大渲染時會顯示在畫面的上方
*/

const GAME_OBJECT_DEPTH = {
  bathtub: 0,
  highlight: 1,
  poop: 2,
  food: 3,
  egg: 4,
  tortoise: 5,
  highlight_body: 6,
  heal_popup: 7,
  brush: 8,
  name: 9
}

export {
  GAME_OBJECT_DEPTH
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/libs/cb-static/cb.util'
import '@/libs/cb-static/sprintf.min'
import '@/libs/cb-static/cb.date'
import '@/libs/pt-theme'
import audio_manager from '@/components/audio_manager'

Vue.config.productionTip = false

Vue.directive('click-sound', {
  inserted(el) {
    el.addEventListener('click', () => {
      audio_manager.play_audio('sound_effect', 'click_btn')
    })
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div :class="{'collection_book_root': true}">
    <Header
      :title="'圖鑑'"
      @close="$emit('update:collection_book_visible', false)"
    >
      <template v-slot:right_content>
        <div
          class="wood_btn q_and_a"
          @click="view_qa()"
        >
          Q&A
        </div>
      </template>
    </Header>
    <Modal
      :visible.sync="qa_modal_visible"
      title="圖鑑Q & A"
      mask_closable
      closable
      :footer_visible="false"
      card_style="qa_modal"
    >
      <template v-slot:content>
        <div class="qa_container">
          <div class="question">
            Q1：一二星龜名字怎麼這麼隨意？
          </div>
          <div class="answer">
            A：我們是一群工程師，平常寫程式碼為變數命名已經很苦惱了，對於創意名字實在是太困難啦！如果有更好的名字歡迎至意見回饋或是粉絲專頁投稿～
          </div>
        </div>
        <div class="qa_container">
          <div class="question">
            Q2：三星龜每隻都好特別！
          </div>
          <div class="answer">
            A：三星龜是真實存在在地球上的陸龜哦！可以拿名字去google看看，如果資料有誤也歡迎到意見回饋或是粉絲專頁跟我們說，也可以來許願希望有什麼品種的烏龜哦
          </div>
        </div>
        <div class="qa_container">
          <div class="question">
            Q3：烏龜產地怎麼來的？
          </div>
          <div class="answer">
            A：三星龜上網查的，其餘的有些是查一些原產地，有些是用抽籤程式決定的
          </div>
        </div>
        <div class="qa_container">
          <div class="question">
            Q4：烏龜尺寸怎麼來的？
          </div>
          <div class="answer">
            A：三星龜算比例，其餘的抽籤程式決定
          </div>
        </div>
        <div class="qa_container">
          <div class="question">
            Q5：圖鑑數量怎麼那麼少？
          </div>
          <div class="answer">
            A：目前只有一位設計師，沒錢錢再請新的來...請大家多多看廣告幫我們賺錢錢 ( 對不起圖鑑...我真的需要那個錢錢 )
          </div>
        </div>
      </template>
    </Modal>
    <main>
      <div
        class="collection_list"
      >
        <div
          v-for="collection in sorted_collection_list"
          :key="'collection_' + collection.variety_id"
          :id="'collection_card_' + collection.variety_id"
          class="collection_card"
          :class="collection.status"
          @click="view_variety_info(collection, $event)"
        >
          <img
            class="collection_bg"
            src="@/assets/imgs/background/collection_bg.png"
            alt="collection_bg"
          >
          <img
            :src="collection.img"
            class="tortoise_img"
          >
          <div class="stars_container">
            <img
              v-for="i in collection.stars"
              :key="'star_' + i"
              src="@/assets/imgs/UI/star.png"
            >
          </div>
          <img
            src="@/assets/imgs/UI/new_collection.png"
            class="new_collection_img"
            v-show="collection.status === 'unlocked_receivable'"
          >
          <img
            src="@/assets/imgs/background/collection_unknow.png"
            class="unkhown_img"
            v-show="collection.status === 'locked'"
          >
        </div>
      </div>
      <Modal
        :visible.sync="card_info_visible"
        mask_closable
        closable
        :footer_visible="false"
        card_style="card_info_modal"
      >
        <template v-slot:header>
          <div class="bg_container">
            <div
              class="collection_card"
            >
              <img
                class="collection_bg"
                src="@/assets/imgs/background/collection_bg.png"
                alt="collection_bg"
              >
              <img
                :src="selected_variety.img"
                class="tortoise_img"
              >
              <div id="unlock_reward">
                <div
                  class="unlock_reward_container"
                  v-show="tortoise_unlocked_receivable"
                  @click="get_unlock_reward()"
                >
                  <div class="reward_header">
                    首抽
                  </div>
                  <img
                    src="@/assets/imgs/UI/coin.png"
                    class="coin"
                  >
                  <span class="price">+{{ selected_variety.unlock_reward }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:content_text>
          <div class="darken_block">
            <div class="tortoise_breed">{{ selected_variety.breed }}</div>
            <div class="info_stars_container">
              <img
                  v-for="i in selected_variety.stars"
                  :key="'star_' + i"
                  class="star"
                  src="@/assets/imgs/UI/star.png"
              >
            </div>
            <div class="info_list">
              <div class="place">產地</div>
              <div class="distribution">{{ selected_variety.distribution }}</div>
            </div>
          </div>
        </template>
      </Modal>
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header'
import collection_manager from "@/components/collection_manager"
import audio_manager from "@/components/audio_manager"

export default {
  name: "CollectionBook",

  components: {
    Header
  },

  props: {
    money: {
      type: Object
    },
    collection_book_visible: {
      type: Boolean
    }
  },

  data() {
    return {
      coin_url: require("@/assets/imgs/UI/coin.png"),
      collection_manager: collection_manager,
      qa_modal_visible: false,
      card_info_visible: false,
      selected_variety: {}
    }
  },

  computed: {
    tortoise_unlocked_receivable() {
      const selected_variety = this.collection_list.find((selected_variety) => {
        return selected_variety.variety_id === this.selected_variety.variety_id
      })
      return !selected_variety?.unlock_reward_received
    },

    collection_list() {
      return this.collection_manager.collection_list
    },

    sorted_collection_list() {
      /**
       * 排序邏輯：
       * 1. 星等小的在前面
       * 2. variety_id小的在前面
       */
      return this.collection_list.sort((a, b) => {
        if (a.stars > b.stars) {
          return 1
        }
        else if (a.stars < b.stars) {
          return -1
        }
        else {
          if (a.variety_id > b.variety_id) {
            return 1
          }
          return -1
        }
      })
    }
  },

  methods: {
    mutate_collection_data(variety_id, key, val) {
      collection_manager.mutate(variety_id, key, val)
    },

    view_variety_info(collection) {
      gtag('event', 'view_variety_info')
      this.selected_variety = collection
      if ( collection.status === 'unlocked_receivable' || collection.status === 'unlocked_received') {
        this.card_info_visible = true
      }
      audio_manager.play_audio('sound_effect', 'click_btn')
    },

    get_unlock_reward() {
      // 要點到小龜詳細資訊頁上的首抽後，原圖鑑上的New也要一起消失。
      const selected_variety = this.selected_variety

      try {
        let unlock_reward = document.getElementById('unlock_reward')

        let effect_div = document.createElement("div")
        effect_div.className = 'coin_effect'
        effect_div.innerHTML = `<img src="${this.coin_url}">+${selected_variety.unlock_reward}`

        unlock_reward.appendChild(effect_div)
        effect_div.onanimationend = () => unlock_reward.removeChild(effect_div)
      }
      catch(error) {
        console.warn("the animation of get_unlock_reward failed", error);
      }

      this.money.earn(selected_variety.unlock_reward)
      gtag('event', 'get_unlock_reward')

      this.mutate_collection_data(selected_variety.variety_id, "unlock_reward_received", true)
    },

    view_qa() {
      gtag('event', 'view_qa')
      this.qa_modal_visible = true
    },

    view_card_info(variety_id) {
      this.card_info_visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.bg_container {
  padding: 30px 0px 10px;
}

.info_list {
  background-color: rgb(110, 73, 46);
  border-radius: 8px;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  padding: $small; //$small:8px
  text-align: left;

  .place {
    color: rgb(203, 176, 140);
    font-weight: bold;
  }
  .distribution {
    color: rgb(255, 248, 237);
  }
}

.tortoise_breed {
  font-size: 20px;
}

.info_stars_container {
  img {
    height: 20px;
    width: 20px;
    margin: 1px;
    z-index: 10;
  }
}

.unlock_reward_container {
  background-color: rgb(224, 87, 48);
  border-radius: 15px;
  box-shadow: 1px 1px 2px rgba(77, 41, 18, 0.5);
  bottom: 0;
  display: flex;
  font-weight: bold;
  justify-content: center;
  left: 50%;
  line-height: 16px;
  padding: $ex-small $small;
  position: absolute;
  transform: translate(-50%, -50%);
  text-shadow: none;

  .reward_header {
    color:rgb(255, 255, 255);
  }

  .coin {
    width: 17px;
    height: 17px;
  }
  .price {
    color: rgb(241, 185, 58);
  }
}

.qa_container {
  font-weight: initial;
}

.answer {
  padding: $small 12px; //$small:8px
  text-align: justify;
}

.question {
  background-color: rgb(110, 73, 46);
  border-radius: 8px;
  padding: $small; //$small:8px
  text-align: left;
}

.q_and_a {
  border-radius: $large; //$large:24px
  font-size: 16px;
  letter-spacing: 2px;
  padding: 0 $medium; //$medium:16px
  margin: 0;

  box-shadow: 0px 3px 3px rgba(77, 51, 18, 0.4);
  text-shadow: 1px 1px 1px rgba(77, 51, 18, 0.4);
}

.collection_book_root {
  @include size;
  @include abs_position(0, 0);
  @include UI_layer;
  display: flex;
  flex-direction: column;
}

main {
  margin-top: unquote('max(48px, constant(safe-area-inset-top) + 48px)');
  margin-top: unquote('max(48px, env(safe-area-inset-top) + 48px)');
  position: relative;
  background-image: url(~@/assets/imgs/background/paper_bg.jpg);
  background-repeat: repeat;
  width: 100%;
  overflow: auto;
  flex: 1;
}

.collection_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: $medium;
  padding: $medium;
}
// 手機畫面至少要有三排
@media screen and (max-width: 420px) {
  .collection_list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.collection_card {
  border: 6px solid white;
  border-radius: $ex-small; //$ex-small:4px
  box-shadow: 1px 1px 1px rgba(77, 41, 18, 0.2);
  position: relative;
  box-sizing: border-box;
  display: flex;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }

  > .collection_bg {
    @include size;
  }

  > .tortoise_img {
    @include abs_position(50%, 50%);
    transform: translate(50%, -50%);
    width: 80%;
  }

  &.unlocked_receivable {
    cursor: pointer;
  }
}

.unkhown_img {
  @include full_size;
}

.stars_container {
  padding-top: 50px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    height: 20px;
    width: 20px;
    margin: 1px;
    z-index: 10;
  }
}

.new_collection_img {
  position: absolute;
  left: -10px;
  top: 10px;
  height: 20%;
  z-index: 11;
}


</style>

<!-- Style for get_unlock_reward animation-->
<style lang="scss">
.qa_modal {
  width: 80% !important;
  height: 90%;
}

.modal-container {
  &.mask {
    background-color: rgba($color-black--80, 0.7) !important;
  }
}

.card_info_modal {
  width: 80% !important;
  max-width: 400px;
}

.coin_effect {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: $color-gold;
  text-shadow: 1px 1px rgb(239, 140, 39);
  animation: coin_effect-anim .8s linear;
  z-index: 100;

  > img {
    width: 20px;
    height: 20px;
    margin: 2px;
  }
}

@keyframes coin_effect-anim {
  50% {
    opacity: 1;
  }
  100% {
    top: 70%;
    opacity: 0;
  }
}
</style>

import Vue from 'vue'
import { WeatherRepository } from '@/repositories/Weather'

const DEFAULT_OBS = {
  "pres": 1017.8,
  "rh": 84.6,
  "precp": 0,
  "tx": 18.0,
  "wd": 89.1,
  "ws": 3.3,
  "lat": 25.0327746,
  "lon": 121.5183069,
  "cloud": 0.5,
  "wx": "晴天",
}

const WeatherVState = new Vue({
  data() {
    return {
      obs_data: {}, // 後端只會回傳一筆資料
      obs_data_received: false,

      location: {},
      obs_data_loading: false
    }
  },
  computed: {
    environ_state() {
      /**
       * 環境狀態: 0-6
       * unknown: 不明（天氣不明或溫度溫度不明）
       * cold_clear: 低溫晴天（晴天和多雲在後端目前都處理成晴天）
       * cold_cloudy: 低溫陰天
       * cold_rainy: 低溫雨天
       * moderate_clear: 適溫晴天
       * moderate_cloudy: 適溫陰天
       * moderate_rainy: 適溫雨天
       *
       * 低溫：<19度
       * 適溫：>=19度（尚未考慮高溫的狀況）
       */

      if (!this.obs_data || this.obs_data.tx === undefined || this.obs_data.tx === null) return 'unknown'

      let state = ''

      if (this.obs_data.tx >= 19) {
        state += 'moderate'
      }
      else {
        state += 'cold'
      }

      switch(this.obs_data.wx) {
        case '晴天':
          state += '_clear'
          break
        case '陰天':
          state += '_cloudy'
          break
        case '雨天':
          state += '_rainy'
          break

        default:
          return 'unknown'
      }

      return state
    }
  },

  methods: {
    stop_raining() {
      //clear out everything
      let rain_container = document.getElementsByClassName('rain')
      for (let container of rain_container) {
        container.innerHTML = ''
      }

    },

    raining() {
      this.stop_raining()

      var increment = 0;
      var drops = "";
      var backDrops = "";
      const drops_div = document.createElement('div')
      const back_drops_div = document.createElement('div')

      while (increment < 100) {
        //couple random numbers to use for various randomizations
        //random number between 98 and 1
        var randoHundo = (Math.floor(Math.random() * (98 - 1 + 1) + 1));
        //random number數字越大，雨越稀疏
        let rain_density = 30
        if (this.obs_data.precp > 0.5) {
          rain_density = 5
        }
        var randoFiver = (Math.floor(Math.random() * (rain_density - 2 + 1) + 2));
        //increment
        increment += randoFiver;
        if (increment < 95) {
          //add in a new raindrop with various randomizations to certain CSS properties
          drops += `
            <div class="drop" style="left:${increment}%; bottom:${(randoFiver + randoFiver - 1 + 100)}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;">
              <div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div>
              <div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div>
            </div>
          `
          backDrops += `
            <div class="drop" style="right: ${increment}%; bottom:${randoFiver + randoFiver - 1 + 100}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;">
              <div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div>
              <div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div>
            </div>
          `
        }
      }
      drops_div.innerHTML = drops
      back_drops_div.innerHTML = backDrops

      document.getElementsByClassName('front-row')[0].appendChild(drops_div)
      document.getElementsByClassName('back-row')[0].appendChild(back_drops_div)
    },

    get_obs() {
      if (this.location && this.location.lat && this.location.lon) {
        let get_obs = WeatherRepository.get_obs({
          'lat': this.location.lat,
          'lon': this.location.lon
        })
        let obs_fallback = null
        let get_obs_fallback = new Promise(resolve => {
          obs_fallback = setTimeout(() => {
            console.warn("get_obs() timeout, use default obs data as weather data.")
            resolve({
              code: 200,
              data: DEFAULT_OBS,
              message: "Use fallback obs data."
            })
          }, 6000)
        })
        Promise.race([get_obs, get_obs_fallback]).then(data => {
          if (data) {
            this.obs_data = data.data
          }
        }).catch(() => {
          console.warn("No weather data, use default obs data as weather data.")
          this.obs_data = DEFAULT_OBS
        }).finally(() => {
          clearTimeout(obs_fallback)
          this.obs_data_received = true
          this.obs_data_loading = false
        })
      }
      else {
        console.warn("No location/lat/lon, use default obs data as weather data.")
        this.obs_data = DEFAULT_OBS
        this.obs_data_received = true
        this.obs_data_loading = false
      }
    },

    update_location() {
      const geo_options = {
        enableHighAccuracy: true,
        timeout: 6000
      }
      const get_location_success = (pos) => {
        this.location = {
          lat: pos.coords.latitude,
          lon: pos.coords.longitude
        }
      }
      const get_location_error = () => {
        // 如果使用者GPS沒開啟，抓台北市中正區的天氣
        this.location = {
          lat: 25.0327746,
          lon: 121.5183069
        }
      }

      this.obs_data_loading = true
      navigator.geolocation.getCurrentPosition(get_location_success, get_location_error, geo_options)
    },
  },

  created() {
    this.update_location()
  },

  destroyed() {
  },

  watch: {
    environ_state() {
      if (this.environ_state.includes('rainy')) {
        this.raining()
      }
      else {
        this.stop_raining()
      }
    },

    location: {
      handler() {
        this.get_obs()
      },
      deep: true
    }
  }
})


export {
  WeatherVState
}

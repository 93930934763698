<template>
  <div class="dark_bg" v-show="visible">
    <div class="card--board">
      <i
        class="icon-symbol_close card_close_btn"
        @click="$emit('update:visible', false)"
        v-click-sound
      >
      </i>
      <div class="title">
        任務板
      </div>
      <div class="darken_block">
        <transition-group name="flip-list" class="mission_list">
          <div
            v-for="mission in sorted_mission_list"
            :key="'mission_' + mission.mission_id"
            class="mission_card"
            :class="{highlight: mission.status === 1}"
            @click="receive_reward(mission)"
          >
            <div class="reward_block">
              <div class="coin_amount">
                <img src="@/assets/imgs/UI/coin.png" alt="coin">
                <div class="amount">{{ mission.reward }}</div>
              </div>
            </div>
            <div class="mission_info">
              <div>
                {{mission.desc}}
              </div>
              <div
                class="progress_bar"
                v-if="mission.status !== 1"
              >
                <div
                  class="filled_part mission"
                  :style="'width: ' + mission.progress_ratio * 100 + '%'"
                ></div>
                <span class="progress_text">{{ mission.progress }}/{{ mission.goal }}</span>
              </div>
              <div
                class="progress_bar"
                v-else
              >
                <div
                  class="filled_part mission_completed"
                ></div>
                <span class="progress_text">領取</span>
              </div>
            </div>
            <div
              v-if="mission.status === 3"
              class="disabled_mask"
            >
              <span>距離下次任務還有 {{ rest_time_info[mission.mission_id] }}</span>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <Modal
      :visible.sync="double_confirm_visible"
      title="獎勵加倍！"
    >
      <template v-slot:content>
        <div>
          觀看影片可將任務獎勵變成2倍喔
        </div>
        <div class="reward_demo">
          <div class="reward_amount origin">
            <img src="@/assets/imgs/UI/coin.png" alt="">
            {{ receiving_mission.reward }}
          </div>
          <i class="icon-arrow_right"></i>
          <div class="reward_amount double">
            <img src="@/assets/imgs/UI/coin.png" alt="">
            {{ receiving_mission.reward * 2 }}
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="double_confirm_footer">
          <button
            class="wood_btn"
            @click="confirm_to_receive_reward()"
            v-click-sound
          >
            領取原獎勵
          </button>
          <button
            class="wood_btn gold_btn"
            @click="confirm_to_receive_reward(true)"
            :disabled="is_loading_ad"
            v-click-sound
          >
            <i class="icon-film"></i>
            2倍獎勵
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import mission_manager from '@/components/mission_manager'
import audio_manager from "@/components/audio_manager"
import { MISSION_CHOICES } from "@/libs/missions"
import { reward_ad_manager } from '@/libs/ad'

export default {
  name: 'MissionBoard',

  props: {
    visible: {
      type: Boolean,
      required: true
    },

    money: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      mission_manager: mission_manager,

      double_confirm_visible: false,
      is_loading_ad: false,

      rest_time_info: {},
      refresh_timer: null,
      receiving_mission: 20
    }
  },

  computed: {
    mission_list() {
      return this.mission_manager.mission_list
    },

    sorted_mission_list() {
      /**
       * 排序邏輯：
       * 1. 可領取的在最前面
       * 2. 等待重置的在最後面
       * 3. 進度多的在前面
       * 4. mission_id小的在前面
       */
      return this.mission_list.sort((a, b) => {
        if (a.status > b.status) {
          return 1
        }
        else if (a.status < b.status) {
          return -1
        }
        else {
          if (a.progress_ratio < b.progress_ratio) {
            return 1
          }
          else if (a.progress_ratio > b.progress_ratio) {
            return -1
          }
          else {
            if (a.mission_id > b.mission_id) {
              return 1
            }
            return -1
          }
        }
      })
    }

  },

  methods: {
    receive_reward(mission) {
      if (mission.status !== 1) {
        return
      }
      audio_manager.play_audio('sound_effect', 'click_btn')

      this.receiving_mission = mission
      const random_num = Math.random()

      // Irene@2021-0910 應需求將機率改為 1/2
      // 原本為 3 / length of missions
      if (random_num < 1/2) {
        this.double_confirm_visible = true
      }
      else {
        this.confirm_to_receive_reward()
      }
    },

    confirm_to_receive_reward(is_double) {
      const mission_id = this.receiving_mission.mission_id
      let reward = this.receiving_mission.reward
      if (is_double) {
        this.is_loading_ad = true

        // show ad
        // show_ad(rewarded_callback, failed_callback, closed_callback)
        reward_ad_manager.show_ad(() => {
          this.money.earn(reward * 2)
          mission_manager.receive_reward(mission_id)
          this.double_confirm_visible = false

          // 看廣告的任務
          mission_manager.make_progress(MISSION_CHOICES.WATCH_1_AD)
        }, () => {
          this.$pt_alert({
            title: "錯誤",
            content: "廣告載入失敗，請稍後再試"
          })
          this.is_loading_ad = false
        }, () => {
          this.is_loading_ad = false
        })
      }
      else {
        this.money.earn(reward)
        mission_manager.receive_reward(mission_id)
        this.double_confirm_visible = false
      }
    },

    check_reset_time() {
      if (mission_manager.time_to_reset_missions && mission_manager.time_to_reset_missions <= new Date()) {
        mission_manager.reset_daily_missions()
        return
      }

      let rest_time_info = {}
      // 等待重置的任務清單
      let cooling_mission_list = this.mission_list.filter(m => m.status === 3)
      for (let mission of cooling_mission_list) {
        let rest_time = '--'
        let rest_millisecs = mission_manager.time_to_reset_missions - new Date()

        rest_time = new Date(rest_millisecs).toUTC().strftime('%H:%M:%S')
        rest_time_info[mission.mission_id] = rest_time

        // 下三行是為了舊玩家
        if (mission_manager.time_to_reset_missions - mission.reward_receive_at >= 24 * 60 * 60 * 1000) {
          mission_manager.reset_mission(mission.mission_id)
        }
      }
      this.rest_time_info = rest_time_info
    }
  },

  mounted() {
    // 每秒去計算任務重置剩餘時間
    if (this.refresh_timer) clearInterval(this.refresh_timer)
    this.refresh_timer = setInterval(() => {
      this.check_reset_time()
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.refresh_timer)
  },
}
</script>

<style lang="scss" scoped>
// extend .card--board
.card--board {
  height: 100%;
}

.mission_list {
  display: flex;
  flex-direction: column;
  gap: $small; //$small:8px
}

.mission_card {
  color: rgb(110, 73, 46);
  background-color: rgb(242, 227, 206);
  border: solid 2px rgb(203, 176, 140);
  border-radius: $small; //$small:8px
  display: flex;
  position: relative;

  &.highlight {
    background-color: rgb(255, 227, 165);
    cursor: pointer;
  }
}

.reward_block {
  @include size(54px, null);
  min-height: 54px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  background-color: rgb(110, 73, 46);
  margin: $ex-small; //$ex-small:4px

  display: flex;
  align-items: center;
  justify-content: center;
}

.coin_amount {
  // position: relative;

  > img {
    @include size(32px);
    @include abs_position($left: 50%, $top: 50%);
    transform: translate(-60%, -55%);
  }

  > div {
    @include abs_position($left: 50%, $top: 50%);
    font-weight: bold;
    color: rgb(242, 227, 206);
    text-shadow: 0px 1px 1px rgba(77, 41, 18, 0.5);
  }
}

.mission_info {
  text-align: left;
  padding: $small; //$small:8px
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .progress_bar {
    @include size(100%, 20px); //bar的高度一定要實體單位(px)才會吃得到
    margin: $small 0 0 0;

    > .progress_text {
      font-size: 14px;
    }
  }
}

.disabled_mask {
  @include size;
  @include abs_position(0, 0, 0, 0);
  background-color: rgba(98, 87, 74, 0.8);
  border-radius: $small; //$small:8px
  z-index: 1;  // 為了蓋過 progress_text

  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    color: white;
    font-weight: bold;
  }
}

.reward_demo {
  display: flex;
  align-items: center;
  width: min-content;
  margin: auto;
  margin-top: $medium; //$medium:16px

  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 248, 237);
  text-shadow: 1px 1px 1px rgba(77, 41, 18, 0.5);

  > .reward_amount {
    display: flex;
    align-items: center;
    margin: 0 $medium; //$medium:16px

    > img {
      margin-right: $small; //$small:8px
    }

    &.origin {
      font-size: 16px;
      > img {
        @include size(20px);
      }
    }
    &.double {
      font-size: 24px;

      > img {
        @include size(32px);
      }
    }
  }
}

.flip-list-move {
  transition: transform 1s;
}
</style>

import GM from '@/components/game_manager'

class PlaceManager {
  constructor() {
    this.places = []
    this.current_place_idx = 0
    this.init_place_data()
  }

  get MAX_NUM_OF_PLACES() {
    return 3
  }

  get MAX_NUM_OF_PLACE_SOULS() {
    return 15
  }

  get places_limit_reached() {
    return this.places.length >= this.MAX_NUM_OF_PLACES
  }

  get current_place() {
    return this.places[this.current_place_idx]
  }

  init_place_data() {
    let record = JSON.parse(localStorage.getItem("place_info"))
    if (!record) {
      this.add_place()
    }
    else {
      this.places = record.places
      this.current_place_idx = record.current_place_idx
    }
  }

  add_place() {
    this.places.push({
      poop_amount: 0
    })
    this.save()
  }

  set_current_place_idx(val) {
    this.current_place_idx = val
    this.save()
  }

  get_place_tortoises(idx) {
    return GM.souls?.filter(soul => soul.place_idx === idx)
  }

  // 指定園區的烏龜數量是否已達上限
  is_place_full(idx) {
    let place_tortoises = this.get_place_tortoises(idx)
    if (place_tortoises) {
      return place_tortoises.length >= this.MAX_NUM_OF_PLACE_SOULS
    }
    else {
      return true
    }
  }

  add_place_poop_amount(idx, amount) {
    if (this.places[idx].poop_amount + amount >= 0) {
      this.places[idx].poop_amount += amount
    }

    this.save()
  }

  save() {
    localStorage.setItem('place_info', JSON.stringify({
      places: this.places,
      current_place_idx: this.current_place_idx
    }))
  }
}

const place_manager = new PlaceManager()

export default place_manager
